import React, { useState, useEffect, useCallback } from "react";
import { Stepper, Step, StepLabel, makeStyles } from "@material-ui/core";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import ReviewEnrollment from "./Steps/ReviewEnrollment";
import EnrollmentConfirmation from "./Steps/EnrollmentConfirmation";
import moment from "moment";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../state/actions/updateEnrollData";
import clearEnrollData from "../../../state/actions/clearEnrollData";
import { withRouter } from "react-router-dom"
import axios from "axios";
import { browserName, browserVersion } from "react-device-detect";

const MBRxChange2025 = ({
  // disableEFTOption,
  // setDisableEFTOption,
  // displayBankValidationMessage,
  // setDisplayBankValidationMessage,
  // counter,
  // setCounter,
  history
}) => {
  // console.log("Public URL: " + process.env.REACT_APP_PUBLIC_URL);

  const { state, actions } = useStateMachine({
    updateEnrollData,
    clearEnrollData,
  });

  const [backButtonDisabled, setBackButtonDisabled] = useState(false);

  const useStyles = makeStyles((theme) => ({
    iconContainer: {
      // define styles for icon container
      transform: "scale(2)",
    },
    alternativeLabel: {
      fontSize: "16px",
    },
  }));

  const classes = useStyles();

  //const [activeStep, setActiveStep] = useState(0);
  //const [formValues, setFormValues] = useState(enrollData);

  const handleNext = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    actions.updateEnrollData({
      activeStep: state.enrollData.activeStep + 1,
    });
    history.push(window.location.pathname);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep - 1);
    actions.updateEnrollData({
      activeStep: state.enrollData.activeStep - 1,
    });
    history.push(window.location.pathname);
    window.scrollTo(0, 0);
  };

  const handleJumpTo = (stepIndex) => {
    //setActiveStep(stepIndex);
    actions.updateEnrollData({
      activeStep: stepIndex,
    });
    history.push(window.location.pathname);
    window.scrollTo(0, 0);
  };

  const handleChange = (input) => (e) => {
    actions.updateEnrollData({ [input]: e.target.value });
  };

  //creating function to load ip address from the API
  const getIPAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    return res.data.IPv4;
  };

  const formName = "Change2025";

  const setDefaultValues = useCallback(() => {
    let a =
      state.enrollData.activeStep === undefined
        ? 0
        : state.enrollData.activeStep;

    if (state.enrollData.activeForm !== formName) {
      actions.clearEnrollData();
      a = 0;
    }

    actions.updateEnrollData({
      recversion: "2025-01",
      transactiontype: "PLNCHG",
      signature: "Y",
      datesigned: moment().format("MM/DD/YYYY"),
      ipaddress: getIPAddress(),
      activeStep: a,
      activeForm: formName,
      browsername: browserName,
      browserversion: browserVersion,
    });
  }, [state]);

  const partADate = {
    month: "",
    day: "",
    year: "",
  };

  const [partADateString, setPartADateString] = useState({ partADate });

  const partBDate = {
    month: "",
    day: "",
    year: "",
  };

  const [partBDateString, setPartBDateString] = useState({ partBDate });

  const reqEffDate = {
    month: "",
    day: "",
    year: "",
  };

  const [aep, setAep] = useState(false);

  const [reqEffDateString, setReqEffDateString] = useState({ reqEffDate });

  const sepOtherDate2 = {
    month: "",
    day: "",
    year: "",
  };

  const [sepOtherDate2String, setSepOtherDate2String] = useState({
    sepOtherDate2,
  });

  const sepOtherDate3 = {
    month: "",
    day: "",
    year: "",
  };

  const [sepOtherDate3String, setSepOtherDate3String] = useState({
    sepOtherDate3,
  });

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [diffAddress, setDiffAddress] = useState(false);

  const [newToMedicare, setNewToMedicare] = useState(false);

  const [svcAreaMove, setSvcAreaMove] = useState(false);

  const [ltc, setLtc] = useState(false);

  const [return2us, setReturn2Us] = useState(false);

  const [lostCoverage, setLostCoverage] = useState(false);

  const [disenrlSnp, setDisenrlSnp] = useState(false);

  const [nonRenewal, setNonRenewal] = useState(false);

  const [lostPdpPartB, setLostPdpPartB] = useState(false);

  const [maOep, setMaOep] = useState(false);

  const [leavingEmpCoverage, setLeavingEmpCoverage] = useState(false);

  const [leavingMa, setLeavingMa] = useState(false);

  const [extraHelp, setExtraHelp] = useState(false);

  const [medicarePremCoverage, setMedicarePremCoverage] = useState(false);

  const [medicaid, setMedicaid] = useState(false);

  const [lawfulPresence, setLawfulPresence] = useState(false);

  const [chooseDifferent, setChooseDifferent] = useState(false);

  const [emergency, setEmergency] = useState(false);

  const [incarceration, setIncarceration] = useState(false);

  const [other, setOther] = useState(false);

  const [preparer, setPreparer] = useState("");

  const [callCenterAssist, setCallCenterAssist] = useState(false);

  const [agentSignedPaper, setAgentSignedPaper] = useState("");

  const accessibilityFormat = {
    braille: false,
    largePrint: false,
    cd: false,
  };

  const [af, setAf] = useState(accessibilityFormat);

  const [seppap, setSeppap] = useState(false);

  function getSteps() {
    return [
      "Step 1",
      "Step 2",
      "Step 3",
      "Step 4",
      "Step 5",
      "Review Enrollment",
      "Enrollment Confirmation",
    ];
  }

  const steps = getSteps();

  useEffect(() => {
    if (state.enrollData.activeForm === formName &&
      state.enrollData.activeStep === 6) {
        // Redirect to Home Page on Reload button
        history.push('/'); 
      }
    else {
    setDefaultValues();
    }
  }, []);


    // clear Enroll Data if user navigates away after clicking Send Now button
    useEffect(() => () => {
      if (backButtonDisabled) {
        actions.clearEnrollData();
        actions.updateEnrollData({ activeStep: 0, activeForm: "" });
      }
    }, []);

  ///////////////////////////////////
  // Handle Back and Forward buttons
  ///////////////////////////////////
  const [locationHistory, setLocationHistory] = useState({});

  useEffect(() => {

    if (history.action === 'PUSH') {

      setLocationHistory(locationHistory => ({ ...locationHistory, [history.location.key]: state.enrollData.activeStep }))

    }
    else if (history.action === "POP") {

      if (!backButtonDisabled) {

        var stepNumber = ((history.location.key) ? locationHistory[history.location.key] : 0) ?? 0;

        if (stepNumber >= 0) {
          actions.updateEnrollData({
            activeStep: stepNumber,
          });

        }
      }

      window.scrollTo(0, 0);

    }
  }, [history.location])



  function getStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            //values={formValues}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            acceptTerms={acceptTerms}
            steps={steps}
            //setFormValues={setFormValues}
            diffAddress={diffAddress}
            setDiffAddress={setDiffAddress}
          />
        );
      case 1:
        return (
          <Step2
            //values={formValues}
            handleChange={handleChange}
            //setFormValues={setFormValues}
            partADateString={partADateString}
            setPartADateString={setPartADateString}
            partBDateString={partBDateString}
            setPartBDateString={setPartBDateString}
            reqEffDateString={reqEffDateString}
            setReqEffDateString={setReqEffDateString}
            sepOtherDate2String={sepOtherDate2String}
            setSepOtherDate2String={setSepOtherDate2String}
            sepOtherDate3String={sepOtherDate3String}
            setSepOtherDate3String={setSepOtherDate3String}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            acceptTerms={acceptTerms}
            steps={steps}
            aep={aep}
            setAep={setAep}
            newToMedicare={newToMedicare}
            setNewToMedicare={setNewToMedicare}
            svcAreaMove={svcAreaMove}
            setSvcAreaMove={setSvcAreaMove}
            ltc={ltc}
            setLtc={setLtc}
            return2us={return2us}
            setReturn2Us={setReturn2Us}
            lostCoverage={lostCoverage}
            setLostCoverage={setLostCoverage}
            disenrlSnp={disenrlSnp}
            setDisenrlSnp={setDisenrlSnp}
            nonRenewal={nonRenewal}
            setNonRenewal={setNonRenewal}
            lostPdpPartB={lostPdpPartB}
            setLostPdpPartB={setLostPdpPartB}
            maOep={maOep}
            setMaOep={setMaOep}
            leavingEmpCoverage={leavingEmpCoverage}
            setLeavingEmpCoverage={setLeavingEmpCoverage}
            leavingMa={leavingMa}
            setLeavingMa={setLeavingMa}
            extraHelp={extraHelp}
            setExtraHelp={setExtraHelp}
            medicarePremCoverage={medicarePremCoverage}
            setMedicarePremCoverage={setMedicarePremCoverage}
            medicaid={medicaid}
            setMedicaid={setMedicaid}
            lawfulPresence={lawfulPresence}
            setLawfulPresence={setLawfulPresence}
            chooseDifferent={chooseDifferent}
            setChooseDifferent={setChooseDifferent}
            emergency={emergency}
            setEmergency={setEmergency}
            incarceration={incarceration}
            setIncarceration={setIncarceration}
            other={other}
            setOther={setOther}
            seppap={seppap}
            setSeppap={setSeppap}
          />
        );
      case 2:
        return (
          <Step3
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            acceptTerms={acceptTerms}
            steps={steps}
            // counter={counter}
            // setCounter={setCounter}
            // displayBankValidationMessage={displayBankValidationMessage}
            // setDisplayBankValidationMessage={setDisplayBankValidationMessage}
            // disableEFTOption={disableEFTOption}
            // setDisableEFTOption={setDisableEFTOption}
          />
        );
      case 3:
        return (
          <Step4
            //values={formValues}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            acceptTerms={acceptTerms}
            setAcceptTerms={setAcceptTerms}
            steps={steps}
          />
        );
      case 4:
        return (
          <Step5
            //values={formValues}
            handleChange={handleChange}
            acceptTerms={acceptTerms}
            //setFormValues={setFormValues}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            steps={steps}
            preparer={preparer}
            setPreparer={setPreparer}
            callCenterAssist={callCenterAssist}
            setCallCenterAssist={setCallCenterAssist}
            agentSignedPaper={agentSignedPaper}
            setAgentSignedPaper={setAgentSignedPaper}
            af={af}
            setAf={setAf}
          />
        );

      case 5:
        return (
          <ReviewEnrollment
            //values={formValues}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            acceptTerms={acceptTerms}
            steps={steps}
            handleJumpTo={handleJumpTo}
            diffAddress={diffAddress}
            newToMedicare={newToMedicare}
            svcAreaMove={svcAreaMove}
            ltc={ltc}
            return2us={return2us}
            lostCoverage={lostCoverage}
            disenrlSnp={disenrlSnp}
            nonRenewal={nonRenewal}
            lostPdpPartB={lostPdpPartB}
            maOep={maOep}
            leavingEmpCoverage={leavingEmpCoverage}
            leavingMa={leavingMa}
            extraHelp={extraHelp}
            medicarePremCoverage={medicarePremCoverage}
            medicaid={medicaid}
            lawfulPresence={lawfulPresence}
            chooseDifferent={chooseDifferent}
            emergency={emergency}
            incarceration={incarceration}
            other={other}
            callCenterAssist={callCenterAssist}
            preparer={preparer}
            //setFormValues={setFormValues}
            agentSignedPaper={agentSignedPaper}
            af={af}
            setBackButtonDisabled={setBackButtonDisabled}
          />
        );

      case 6:
        return (
          <EnrollmentConfirmation
            //values={formValues}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            acceptTerms={acceptTerms}
            steps={steps}
            confNum={state.enrollData.confirmationno}
            af={af}
            setBackButtonDisabled={setBackButtonDisabled}
          />
        );
      default:
        return "Unknown Step";
    }
  }

  let p1 = state.enrollData.activeStep === 0 ? true : false;
  // console.log(p1);

  return (
    <div>
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex"></div>
        <section
          id="block-clearstonebreadcrumbs"
          className="
                  block
                  block-clearstone-updates
                  block-clearstone-breadcrumbs-block
                  clearfix
                "
        >
          <div className="breadcrumb-wrap">
            <div className="region--breadcrumb nst-1">
              <section
                id="block-ttheme-breadcrumbs"
                className="block-system block-system-breadcrumb-block"
              >
                <div role="navigation">
                  <ol className="breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>MBRx Change 2025</li>
                  </ol>
                </div>
              </section>
            </div>
          </div>
        </section>

        <Stepper activeStep={state.enrollData.activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                classes={{
                  iconContainer: classes.iconContainer,
                  alternativeLabel: classes.alternativeLabel,
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <div>{getStepsContent(state.enrollData.activeStep)}</div>
      </div>
      <div className="main-container pt-md-5 pb-5 container js-quickedit-main-content">
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <article className="webform full clearfix">
                <div className="content">
                  <p className="toggle2">
                      S5743_091123_G01_M
                    <br />
                  </p>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

//////////////////////////////////////////////////////////////
// Wrap component withRouter to have access to history object
//////////////////////////////////////////////////////////////
const MBRxChange2025WithRouter = withRouter(MBRxChange2025);

export default MBRxChange2025WithRouter;
