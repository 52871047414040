import React from "react";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <nav id="stick-bar" className="navbar navbar-default npa">
      <div className="container">
        <div id="navbar-collapse" className="navbar-collapse">
          <div className="region region-navigation">
            <ul className="menu menu--main nav navbar-nav">
              <li className="first med-home">
                <NavLink to="/">Home</NavLink>
              </li>
              <li className="expanded dropdown med-compare-plans">
                <NavLink to="/compare-plans">
                  Compare plans <span className="caret"></span>
                </NavLink>
                <ul block="block-mainnavigation-2" className="dropdown-menu">
                <li className="med-2020-plan-comparison">
                    <NavLink to="/Compare/PlanCompare2025">
                      2025 Plan comparison
                    </NavLink>
                  </li>
                  <li className="med-2020-plan-comparison">
                    <NavLink to="/Compare/PlanCompare2024">
                      2024 Plan comparison
                    </NavLink>
                  </li>
                  {/* <li className="med-2020-plan-comparison">
                    <NavLink to="/Compare/PlanCompare2023">
                      2023 Plan comparison
                    </NavLink>
                  </li> */}
                  {/* <li className="med-2020-plan-comparison">
                    <NavLink to="/Compare/PlanCompare2022">
                      2022 Plan comparison
                    </NavLink>
                  </li> */}
                  <li className="med-coverage-and-pricing-tools">
                    <NavLink to="/pharmacy-tools-landing">
                      Coverage and pricing tools
                    </NavLink>
                  </li>
                  <li className="med-other-medicare-plans">
                    <NavLink to="/Compare/OtherMedicarePlans">
                      Other Medicare plans
                    </NavLink>
                  </li>
                  <li className="last med-star-rating">
                    <NavLink to="/compare-plans/about-star-ratings">
                      Star rating
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="expanded dropdown med-enroll">
                <NavLink to="/enroll-0" className="dropdown-toggle">
                  Enroll <span className="caret"></span>
                </NavLink>
                <ul block="block-mainnavigation-2" className="dropdown-menu">
                  <li className="first med-2021-enroll-now">
                    <NavLink to="/enroll/2025-enroll-now">
                      2025 Enroll now
                    </NavLink>
                  </li>
                  <li className="first med-2021-enroll-now">
                    <NavLink to="/enroll/2024-enroll-now">
                      2024 Enroll now
                    </NavLink>
                  </li>
                  {/* <li className="first med-2021-enroll-now">
                    <NavLink to="/enroll/2023-enroll-now">
                      2023 Enroll now
                    </NavLink>
                  </li> */}
                  <li className="med-2021-change-plan">
                    <NavLink to="/Enroll/2025-change-plan">
                      2025 Change plan
                    </NavLink>
                  </li>
                  <li className="med-2021-change-plan">
                    <NavLink to="/Enroll/2024-change-plan">
                      2024 Change plan
                    </NavLink>
                  </li>
                  {/* <li className="med-2021-change-plan">
                    <NavLink to="/Enroll/2023-change-plan">
                      2023 Change plan
                    </NavLink>
                  </li> */}
                  {/* <li className="first med-2021-enroll-now">
                    <NavLink to="/enroll/2022-enroll-now">
                      2022 Enroll now
                    </NavLink>
                  </li> */}
                  {/* <li className="med-2021-change-plan">
                    <NavLink to="/Enroll/2022-change-plan">
                      2022 Change plan
                    </NavLink>
                  </li> */}
                </ul>
              </li>
              <li className="expanded dropdown med-members">
                <NavLink to="/Members" className="cus-members dropdown-toggle">
                  Members <span className="caret"></span>
                </NavLink>
                <ul block="block-mainnavigation-2" className="dropdown-menu">
                  <li className="first med-pharmacy-information">
                    <NavLink to="/Members/PharmacyInfo">
                      Filling your prescription
                    </NavLink>
                  </li>
                  <li className="med-drug-information">
                    <NavLink to="/Members/drug-information">
                      Drug coverage
                    </NavLink>
                  </li>
                  <li className="med-claims-history">
                    <NavLink to="/Members/DrugClaims">Claims</NavLink>
                  </li>
                  {/* <li className="med-submit-a-claim">
                    <NavLink to="/Members/SubmitClaim">Submit a claim</NavLink>
                  </li> */}
                  <li className="med-coverage-determinations,-appeals,-grievances">
                    <NavLink to="/Members/coverage-determinations">
                      Coverage determinations, appeals, grievances
                    </NavLink>
                  </li>
                  {/* <li className="med-drug-transition-supply">
                    <NavLink to="/Members/PlanTransitionSupply">
                      Drug transition supply
                    </NavLink>
                  </li> */}
                  {/* <li className="med-mail-order-prescriptions">
                    <NavLink to="/Members/MailOrderPrescriptions">
                      Mail order prescriptions
                    </NavLink>
                  </li> */}
                  <li className="med-order-member-id-card">
                    <NavLink to="/Members/OrderMemberIdCard">
                      Order member ID card
                    </NavLink>
                  </li>
                  <li className="med-medication-therapy-management-program">
                    <NavLink to="/Members/medication-therapy-management-program">
                      Medication Therapy Management program
                    </NavLink>
                  </li>
                  <li className="med-payment-information">
                    <NavLink to="/Members/PaymentInfo">
                      Payment information
                    </NavLink>
                  </li>
                  <li className="last med-member-discounts">
                    <NavLink to="/Members/MemberDiscounts">
                      Member discounts
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="expanded dropdown med-documents">
                <NavLink
                  to="/Documents/IndividualPlanDocs2024"
                  className="dropdown-toggle"
                >
                  Documents <span className="caret"></span>
                </NavLink>
                <ul block="block-mainnavigation-2" className="dropdown-menu">
                  <li className="last med-2020-individual-plan-documents">
                    <NavLink to="/Documents/IndividualPlanDocs2025">
                      2025 Individual plan documents
                    </NavLink>
                  </li>
                  <li className="last med-2020-individual-plan-documents">
                    <NavLink to="/Documents/IndividualPlanDocs2024">
                      2024 Individual plan documents
                    </NavLink>
                  </li>
                  {/* <li className="last med-2020-individual-plan-documents">
                    <NavLink to="/Documents/IndividualPlanDocs2023">
                      2023 Individual plan documents
                    </NavLink>
                  </li> */}
                  {/* <li className="last med-2020-individual-plan-documents">
                    <NavLink to="/Documents/IndividualPlanDocs2022">
                      2022 Individual plan documents
                    </NavLink>
                  </li> */}
                </ul>
              </li>
              <li className="expanded dropdown med-part-d-basics">
                <NavLink to="/PartDBasics" className="dropdown-toggle">
                  Part D basics <span className="caret"></span>
                </NavLink>
                <ul block="block-mainnavigation-2" className="dropdown-menu">
                  <li className="first med-communication-timeline">
                    <NavLink to="/PartDBasics/CommunicationTimeline">
                      Communication Timeline
                    </NavLink>
                  </li>
                  <li className="first med-frequently-asked-questions">
                    <NavLink to="/part-d-basics/frequently-asked-questions">
                      Frequently asked questions
                    </NavLink>
                  </li>
                  <li className="med-eligibility-requirements">
                    <NavLink to="/PartDBasics/EligibilityRequirements">
                      Eligibility requirements
                    </NavLink>
                  </li>
                  <li className="med-when-to-enroll">
                    <NavLink to="/PartDBasics/WhenToEnroll">
                      When to enroll
                    </NavLink>
                  </li>
                  <li className="med-financial-assistance">
                    <NavLink to="/PartDBasics/FinancialAssistance">
                      Financial assistance
                    </NavLink>
                  </li>
                  <li className="last med-glossary">
                    <NavLink to="/PartDBasics/Glossary">Glossary</NavLink>
                  </li>
                </ul>
              </li>
              <li className="expanded dropdown med-employer-group-plans">
                <NavLink to="/GroupBenefits" className="dropdown-toggle">
                  Employer group plans <span className="caret"></span>
                </NavLink>
                <ul block="block-mainnavigation-2" className="dropdown-menu">
                  <li className="first med-group-benefit-solutions">
                    <NavLink to="/EmployerGroupPlans/PartDPlansEmployerAndUnionGroups">
                      Group benefit solutions
                    </NavLink>
                  </li>
                  <li className="med-group-resources">
                    <NavLink to="/EmployerGroupPlans/GroupResources">
                      Group resources
                    </NavLink>
                  </li>
                  <li className="med-2021-group-documents">
                    <NavLink to="/EmployerGroupPlans/EmployerGroupDocs2025">
                      2025 Group documents
                    </NavLink>
                  </li>
                  <li className="med-2021-group-documents">
                    <NavLink to="/EmployerGroupPlans/EmployerGroupDocs2024">
                      2024 Group documents
                    </NavLink>
                  </li>
                  {/* <li className="med-2021-group-documents">
                    <NavLink to="/EmployerGroupPlans/EmployerGroupDocs2023">
                      2023 Group documents
                    </NavLink>
                  </li> */}
                  {/* <li className="med-2021-group-documents">
                    <NavLink to="/EmployerGroupPlans/EmployerGroupDocs2022">
                      2022 Group documents
                    </NavLink>
                  </li> */}
                  <li className="med-group-coverage-tools">
                    <NavLink to="/EmployerGroupPlans/GroupPlanResearchTools">
                      Group coverage tools
                    </NavLink>
                  </li>
                  <li className="med-group-contact-information">
                    <NavLink to="/employer-group-plans/group-contact-information">
                      Group contact information
                    </NavLink>
                  </li>
                  <li className="med-group-communication-timeline">
                    <NavLink to="/EmployerGroupPlans/GroupCommunicationTimeline">
                      Group Communication timeline
                    </NavLink>
                  </li>
                  <li className="last med-fraud,-waste-and-abuse-information">
                    <NavLink to="/EmployerGroupPlans/FWA">
                      Fraud, waste and abuse information
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="last med-contact-us">
                <NavLink to="/contact/medicareblue-rx">Contact us</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
