/* eslint-disable import/no-anonymous-default-export */
export default {
  recversion: "",
  confirmationno: "",
  transactiontype: "",
  lastname: "",
  firstname: "",
  mi: "",
  sal: "",
  dob: "",
  sex: "",
  homephone: "",
  altphone: "",
  permst1: "",
  permst2: "",
  permcity: "",
  permstate: "",
  permzip5: "",
  permzip4: "",
  mailst1: "",
  mailst2: "",
  mailcity: "",
  mailst: "",
  mailzip5: "",
  mailzip4: "",
  memberidnumber: "",
  email: "",
  dor: "",
  planname: "",
  medicareclaimnum: "",
  partadate: "",
  partbdate: "",
  paymentoption: "",
  signature: "",
  datesigned: "",
  authrepfirstname: "",
  authreplastname: "",
  authrepstreet: "",
  authrepcity: "",
  authrepstate: "",
  authrepzip: "",
  authrepphone: "",
  authreprelationship: "",
  assistorsigned: "",
  assistorrelationship2applicant: "",
  assistorsigneddate: "",
  datesignedpaper: "",
  agentid: "",
  agentlast: "",
  agentfirst: "",
  agencyid: "",
  agentphone: "",
  agentsigned: "",
  groupnum: "",
  signedbysomebodyelse: "",
  callcenter: "",
  authrepmail: "",
  longtermcare: "",
  instname: "",
  instadd: "",
  instcity: "",
  inststate: "",
  instzip: "",
  instphone: "",
  adddrugcov: "",
  addcovname: "",
  addcovid: "",
  addgroupid: "",
  aep: "",
  sepmaopenenrollment: "",
  sepnewtomedicare: "",
  effectivedate: "",
  sepserviceareamove: "",
  sepserviceareamovedate: "",
  sepmedicarepremcoverage: "",
  sepextrahelp: "",
  sepextrahelpdate: "",
  sepextrahelpterm: "",
  sepextrahelptermdate: "",
  sepltc: "",
  sepltcdate: "",
  sepltcout: "",
  sepltcoutdate: "",
  seppace: "",
  seppacedate: "",
  seplostcoverage: "",
  seplostcoveragedate: "",
  sepleavingempcoverage: "",
  sepleavingempcoveragedate: "",
  seppap: "",
  sepreturn2us: "",
  sepreturn2usdate: "",
  sepdisenrollmcp: "",
  sepdisenrollmcpdate: "",
  sepleavingma: "",
  sepleavingmadate: "",
  sepdisenrlsnp: "",
  sepdisenrlsnpdate: "",
  sepnonrenewal: "",
  sepnonrenewaldate: "",
  seplostpdppartb: "",
  seplostpdppartbdate: "",
  sepother: "",
  sepothertxt: "",
  sepotherdate: "",
  esrd: "",
  statemedicaid: "",
  medicaidnbr: "",
  workspouseself: "",
  sepnewpartbdate: "",
  timestamp: "",
  sepotherdate2: "",
  sepotherdate3: "",
  ipaddress: "",
  sepincarceration: "",
  sepincarcerationdate: "",
  seplawfulpresence: "",
  seplawfulpresencedate: "",
  accountholdername: "",
  accttype: "",
  bankname: "",
  bankroutingnumber: "",
  bankacctnumber: "",
  demographicseffectivedate: "",
  sepmaoep: "",
  sepmedicaid: "",
  sepmedicaiddate: "",
  sepchoosedifferent: "",
  sepchoosedifferentdate: "",
  sepemergency: "",
  accessibleformatoption: "",

  partAMonth: "",
  partADay: "",
  partAYear: "",

  partBMonth: "",
  partBDay: "",
  partBYear: "",

  reqEffMonth: "",
  reqEffDay: "",
  reqEffYear: "",

  isAep: false,

  sepOtherDate2Month: "",
  sepOtherDate2Day: "",
  sepOtherDate2Year: "",

  sepOtherDate3Month: "",
  sepOtherDate3Day: "",
  sepOtherDate3Year: "",

  acceptTerms: false,
  diffAddress: false,
  newToMedicare: false,
  svcAreaMove: false,
  ltc: false,
  return2us: false,
  lostCoverage: false,
  disenrlSnp: false,
  nonRenewal: false,
  lostPdpPartB: false,
  maOep: false,
  leavingEmpCoverage: false,
  leavingMa: false,
  extraHelp: false,
  medicarePremCoverage: false,
  medicaid: false,
  lawfulPresence: false,
  chooseDifferent: false,
  emergency: false,
  incarceration: false,
  other: false,
  preparer: "",
  callCenterAssist: false,
  agentSignedPaper: "",
  hasAuthRep: false,
  disenrollMcp: false,

  braille: false,
  largePrint: false,
  cd: false,

  pap: false,
  paymentOption: "",

  confirm_bankroutingnumber: "",
  confirm_bankacctnumber: "",
  confirm_email: "",

  cbErrCount: 0,
  abDateErrors: 0,
  aDateStatus: 3,
  bDateStatus: 3,

  activeStep: 0,
  activeForm: "",

  sendConfirmEmail: false,
  confirmationEmail: "",

  searchString: "",
  MD5OfMessageBody: "",

  ethnicity: "",
  ethnicityNA: "",
  ethnicityPR: "",
  ethnicityOTH: "",
  ethnicityMX: "",
  ethnicityCUB: "",
  ethnicityNR: "",

  race: "",
  race300: "",
  race401: "",
  race201: "",
  race411: "",
  race421: "",
  race521: "",
  race431: "",
  race441: "",
  race501: "",
  race499: "",
  race599: "",
  race511: "",
  race451: "",
  race101: "",
  race999: "",

  billingaddress1: "",
  billingaddress2: "",
  billingcity: "",
  billingst: "",
  billingzip5: "",
  billingzip4: "",

  browsername: "",
  browserversion: "",

  // sexassignedatbirth: "",
  gender: "",
  sexualorientation: ""

};
