import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const style = {
  background: "#eff4f6",
  padding: "8px",
  fontWeight: "400",
};

const Home = () => {
  const [content, isLoading] = useContent("2USLxOYUWeLhMljiTFR8eH");

  if (isLoading) return <p>Loading...</p>;

  // console.log(content);

  //console.log(content.sys.updatedAt);

  return (
    <div className="dialog-off-canvas-main-canvas">
      <div className="col-xs-12 p-0 slider-area">
        <div className="container">
          <div className="region region-slider">
            <section
              className="views-element-container block block-views block-views-blockcontent-views-block-4 clearfix"
              id="block-views-block-content-views-block-4"
            >
              <div
                block="block-views-block-content-views-block-4"
                className="form-group"
              >
                <div className="view view-content-views view-id-content_views view-display-id-block_4">
                  <div className="view-content">
                    <div>
                      <div className="slider-image">
                        <div className="field field--name-body">
                          <div className="slide-pic">
                            <img
                              src={
                                content.contentModules[1].fields.backgroundImage
                                  .fields.file.url
                              }
                              className="align-center"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="title-text mb-4 px-3 col-md-6">
                        <h1>{content.contentModules[1].fields.headline}</h1>
                        <h3>{content.contentModules[1].fields.subhead}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div className="container py-5 d-block clear home-section">
        <div className="col-sm-8 col-md-9">
          <div className="region region-content">
            <article className="standard full clearfix">
              <div className="content">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  <h1>{content.ModuleHeading}</h1>
                </div>

                <div className="field field--name-field-table-menu field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="field__item left">
                        {" "}
                        <div className="field-collection-container">
                          <div className="field field--name-field-table-description field--type-text-long field--label-hidden field__item">
                            <ReactMarkdown children={content.bodyText} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="field__item right">
                        {" "}
                        <div className="field-collection-container">
                          <div className="field field--name-field-table-description field--type-text-long field--label-hidden field__item">
                            <ReactMarkdown children={content.bodyText2} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field field--name-field-add-accordion field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    <div id="accordion-4" className="form-group"></div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <aside className="col-sm-4 col-md-3 side-section order-md-1">
          <div className="region region-sidebar-second">
            <section
              id="block-homecontact-3"
              className="contact-gradient p-5 mt-5 block block-block-content block-block-contentc84e5a3b-f998-486b-8e22-4dd0249790fd clearfix"
            >
              <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                <h2>
                  <ReactMarkdown
                    children={content.contentModules[2].fields.titleSidebar1}
                  />
                </h2>

                <ReactMarkdown
                  children={content.contentModules[2].fields.sidebarBodyText}
                />
              </div>
            </section>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Home;
