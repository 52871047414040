import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "@material-ui/core";
import moment from "moment";
//import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../../state/actions/updateEnrollData";
import CSPhoneInput from "../../../Common/CSPhoneInput";

const Step1_2025 = ({
  //values,
  handleChange,
  //setFormValues,
  // diffAddress,
  // setDiffAddress,
  handleNext,
  handlePrevious,
  activeStep,
  // acceptTerms,
  steps,
}) => {
  const { state, actions } = useStateMachine({ updateEnrollData });

  // console.log(state.enrollData);

  const {
    register,
    formState: { errors, isValid },
    getValues,
    control,
    trigger,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  // console.log(errors);

  let p1 = activeStep === 0 ? true : false;

  const handleNextClick = async () => {
    const isValidForm = await trigger(); 

    if (isValidForm) {
      handleNext();
    }
  };

  // const handleHomePhone = (e) => {
  //   actions.updateEnrollData({
  //     ...state.enrollData,
  //     homephone: e.replace("+1", ""),
  //   });
  // };

  // const handleAltPhone = (e) => {
  //   actions.updateEnrollData({ altphone: e.replace("+1", "") });
  // };

  const minDate = "1911-" + moment().format("MM-DD");
  // console.log(minDate);

  return (
    <>
      <div
        className="dialog-off-canvas-main-canvas"
        data-off-canvas-main-canvas=""
      >
        <div
          role="main"
          className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
        >
          <div className="row d-md-flex">
            <section className="col-sm-12 internal-page">
              <div className="region region-content">
                <article className="webform full clearfix">
                  <div className="content">
                    <div>
                      <form
                        className="
                        webform-submission-form
                        webform-submission-add-form                        
                        webform-details-toggle
                      "
                      >
                        <div className="js-form-wrapper form-wrapper form-group">
                          <div
                            id="edit-step-1-title"
                            className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                          >
                            <p>
                              <strong>
                                Asterisk (*) indicates required information
                              </strong>
                              .
                            </p>

                            <p>
                              <strong>
                                Complete this form only if you wish to change
                                your MedicareBlue Rx (PDP) plan option
                              </strong>
                              . For premium or other changes, please call a
                              Medicare Solutions specialist (see phone numbers
                              below). To change to a different Medicare
                              prescription drug plan option with MedicareBlue
                              Rx, complete this form by checking the plan option
                              you want and completing the form.
                            </p>

                            <p>
                              You may change your plan option during the annual
                              enrollment period, from October 15 to December 7.
                              Generally, you may not make changes at other times
                              unless you meet certain special exceptions (see
                              the enrollment period determination section). For
                              more information about enrollment periods please
                              call a Medicare Solutions specialist.
                            </p>

                            <p>
                              If you select another plan option and we receive
                              your completed form by the end of any month, your
                              new benefit plan will generally begin the first of
                              the following month. Selections made during the
                              annual enrollment period (October 15-December 7)
                              are effective January 1.
                            </p>

                            <p>
                              If you have any questions, please call a
                              MedicareBlue Rx Solutions specialist at{" "}
                              <strong>1-866-434-2037 </strong> (TTY:
                              <strong>711</strong>), 8 a.m. to 8 p.m., daily,
                              local time.
                            </p>
                          </div>

                          <div
                            className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            data-drupal-selector="edit-enroll-in-plan"
                            data-msg-required="Enroll me in the plan below is required."
                            id="edit-enroll-in-plan"
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Enroll me in the plan below
                              </div>
                            </div>
                            <div className="panel-body">
                              <fieldset
                                data-drupal-selector="edit-planname"
                                className="
                                radios--wrapper
                                fieldgroup
                                form-composite
                                webform-composite-visible-title
                                required
                                js-webform-type-radios
                                webform-type-radios
                                js-form-item
                                form-item
                                js-form-wrapper
                                form-wrapper
                              "
                                data-msg-required="Choose a plan is required."
                                id="edit-planname--wrapper"
                              >
                                <legend>
                                  <span
                                    className="
                                    fieldset-legend
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Choose a plan
                                  </span>
                                </legend>
                                <div className="fieldset-wrapper">
                                  <div
                                    id="edit-planname"
                                    className="
                                    js-webform-radios
                                    webform-options-display-one-column
                                  "
                                  >
                                    {/* <div
                                      className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-planname
                                      js-form-item-planname
                                      radio
                                    "
                                    >
                                      <label
                                        htmlFor="edit-planname-medicareblue-rx-select"
                                        className="control-label option"
                                      >
                                        <input
                                          {...register("planname", {
                                            required:
                                              "Choose a plan is required.",
                                          })}
                                          onChange={handleChange("planname")}
                                          className="form-radio"
                                          data-msg-required="Choose a plan is required."
                                          type="radio"
                                          id="edit-planname-medicareblue-rx-select"
                                          value="MedicareBlue Rx Select"
                                          required="required"
                                          //aria-required="true"
                                          name="planname"
                                          checked={
                                            state.enrollData.planname ===
                                            "MedicareBlue Rx Select"
                                          }
                                        />
                                        MedicareBlue Rx (PDP) Select
                                      </label>
                                    </div> */}

                                    <div
                                      className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-planname
                                      js-form-item-planname
                                      radio
                                    "
                                    >
                                      <label
                                        htmlFor="edit-planname-medicareblue-rx-standard"
                                        className="control-label option"
                                      >
                                        <input
                                          {...register("planname", {
                                            required:
                                              "Choose a plan is required.",
                                          })}
                                          onChange={handleChange("planname")}
                                          className="form-radio"
                                          data-msg-required="Choose a plan is required."
                                          type="radio"
                                          id="edit-planname-medicareblue-rx-standard"
                                          value="MedicareBlue Rx Standard"
                                          required="required"
                                          //aria-required="true"
                                          name="planname"
                                          checked={
                                            state.enrollData.planname ===
                                            "MedicareBlue Rx Standard"
                                          }
                                        />
                                        MedicareBlue Rx (PDP) Standard
                                      </label>
                                    </div>
                                    <div
                                      className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-planname
                                      js-form-item-planname
                                      radio
                                    "
                                    >
                                      <label
                                        htmlFor="edit-planname-medicareblue-rx-premier"
                                        className="control-label option"
                                      >
                                        <input
                                          {...register("planname", {
                                            required:
                                              "Choose a plan is required.",
                                          })}
                                          onChange={handleChange("planname")}
                                          className="form-radio"
                                          data-msg-required="Choose a plan is required."
                                          type="radio"
                                          id="edit-planname-medicareblue-rx-premier"
                                          value="MedicareBlue Rx Premier"
                                          required="required"
                                          //aria-required="true"
                                          name="planname"
                                          checked={
                                            state.enrollData.planname ===
                                            "MedicareBlue Rx Premier"
                                          }
                                        />
                                        MedicareBlue Rx (PDP) Premier
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <div className="error">
                                <ErrorMessage errors={errors} name="planname">
                                  {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(
                                      ([type, message]) => (
                                        <p key={type}>{message}</p>
                                      )
                                    )
                                  }
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>

                          <div
                            className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            data-drupal-selector="edit-personal-information"
                            data-msg-required="Enrollee information is required."
                            id="edit-personal-information"
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Enrollee information
                              </div>
                            </div>

                            <div className="panel-body">
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                id="edit-enroll-1-row"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-select
                                  js-form-type-select
                                  form-item-sal
                                  js-form-item-sal
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-sal"
                                    className="control-label"
                                  >
                                    Title
                                  </label>

                                  <div className="select-wrapper">
                                    <select
                                      {...register("sal")}
                                      className="form-select form-control"
                                      id="edit-sal"
                                      //defaultValue=""
                                      value={state.enrollData.sal}
                                      onChange={handleChange("sal")}
                                    >
                                      <option value="">- None -</option>
                                      <option value="Mr.">Mr.</option>
                                      <option value="Mrs.">Mrs.</option>
                                      <option value="Ms.">Ms.</option>
                                    </select>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-firstname
                                  js-form-item-firstname
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-firstname"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    First name
                                  </label>

                                  <input
                                    {...register("firstname", {
                                      required: "First name is required.",
                                      maxLength: {
                                        value: 25,
                                        message:
                                          "First name field has a maximum length of 25.",
                                      },
                                      pattern: {
                                        value: /^[-a-zA-Z0-9 ]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                      validate: (value) => {
                                        return (
                                          !!value.trim() ||
                                          "First name is required."
                                        );
                                      },
                                    })}
                                    value={state.enrollData.firstname}
                                    onChange={handleChange("firstname")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-firstname"
                                    className="form-text required form-control"
                                    data-msg-maxlength="First name field has a maximum length of 25."
                                    data-msg-required="First name is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-firstname"
                                    size="60"
                                    maxLength="25"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="firstname"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                id="edit-enroll-2-row"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-mi
                                  js-form-item-mi
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-mi"
                                    className="control-label"
                                  >
                                    Middle initial
                                  </label>

                                  <input
                                    {...register("mi", {
                                        pattern: {
                                          value: /^[-a-zA-Z0-9 ]*$/,
                                          message:
                                            "Special characters are not allowed.",
                                        }                                      
                                    })}
                                    value={state.enrollData.mi}
                                    onChange={handleChange("mi")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-mi"
                                    className="form-text form-control"
                                    data-msg-maxlength="Middle initial	 field has a maximum length of 1."
                                    data-msg-required="Middle initial	 is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-mi"
                                    size="60"
                                    maxLength="1"
                                  />
                                  <div className="error">
                                    <ErrorMessage errors={errors} name="mi">
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-lastname
                                  js-form-item-lastname
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-lastname"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Last name
                                  </label>

                                  <input
                                    {...register("lastname", {                                      
                                        required: "Last name is required.",
                                        maxLength: {
                                          value: 25,
                                          message:
                                            "First name field has a maximum length of 25.",
                                        },
                                        pattern: {
                                          value: /^[-a-zA-Z0-9 ]*$/,
                                          message:
                                            "Special characters are not allowed.",
                                        },
                                        validate: (value) => {
                                          return (
                                            !!value.trim() ||
                                            "Last name is required."
                                          );
                                        }
                                    })}
                                    value={state.enrollData.lastname}
                                    onChange={handleChange("lastname")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-lastname"
                                    className="form-text required form-control"
                                    data-msg-maxlength="Last name field has a maximum length of 25."
                                    data-msg-required="Last name is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-lastname"
                                    size="60"
                                    maxLength="25"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="lastname"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            data-drupal-selector="edit-permanent-resident-address"
                            data-msg-required="Permanent resident address: (Do NOT enter a P.O. Box) is required."
                            id="edit-permanent-resident-address"
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Permanent resident address: (Don't enter a P.O.
                                Box)
                              </div>
                            </div>

                            <div className="panel-body">
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-1"
                                data-msg-required="This field is required."
                                id="edit-row-1"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permst1
                                  js-form-item-permst1
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permst1"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Street address
                                  </label>

                                  <input
                                    {...register("permst1", {
                                      required: "Street address is required.",
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Street address field has a maximum length of 30.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.permst1}
                                    onChange={handleChange("permst1")}
                                    pattern="^[-/a-zA-Z0-9 -]*$"
                                    className="form-text required form-control"
                                    data-msg-maxlength="Street address field has a maximum length of 30."
                                    data-msg-required="Street address is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-permst1"
                                    size="60"
                                    maxLength="30"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permst1"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permst2
                                  js-form-item-permst2
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permst2"
                                    className="control-label"
                                  >
                                    Address 2
                                  </label>

                                  <input
                                    {...register("permst2")}
                                    value={state.enrollData.permst2}
                                    onChange={handleChange("permst2")}
                                    pattern="^[-/a-zA-Z0-9 ]*$"
                                    className="form-text form-control"
                                    data-msg-maxlength="Address 2 field has a maximum length of 30."
                                    data-msg-required="Address 2 is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-permst2"
                                    size="60"
                                    maxLength="30"
                                  />
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-2"
                                data-msg-required="This field is required."
                                id="edit-row-2"
                              >
                                <div
                                  className="
                                  col-sm-3 col-md-3 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permcity
                                  js-form-item-permcity
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permcity"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    City
                                  </label>

                                  <input
                                    {...register("permcity", {
                                      required: "City is required.",
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "City field has a maximum length of 25.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.permcity}
                                    onChange={handleChange("permcity")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    className="form-text required form-control"
                                    data-msg-maxlength="City field has a maximum length of 25."
                                    data-msg-required="City is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-permcity"
                                    size="60"
                                    maxLength="25"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permcity"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-3 col-md-3 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-select
                                  js-form-type-select
                                  form-item-permstate
                                  js-form-item-permstate
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permstate"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    State
                                  </label>

                                  <div className="select-wrapper">
                                    <select
                                      {...register("permstate", {
                                        required: "State is required.",
                                      })}
                                      value={state.enrollData.permstate}
                                      onChange={handleChange("permstate")}
                                      data-drupal-selector="edit-permstate"
                                      className="form-select required form-control"
                                      data-msg-required="State is required."
                                      id="edit-permstate"
                                      required="required"
                                      aria-required="true"
                                    >
                                      <option value="">- Select -</option>
                                      <option value="IA">Iowa</option>
                                      <option value="MN">Minnesota</option>
                                      <option value="MT">Montana</option>
                                      <option value="NE">Nebraska</option>
                                      <option value="ND">North Dakota</option>
                                      <option value="SD">South Dakota</option>
                                      <option value="WY">Wyoming</option>
                                    </select>
                                    <div className="error">
                                      <ErrorMessage
                                        errors={errors}
                                        name="permstate"
                                      >
                                        {({ messages }) =>
                                          messages &&
                                          Object.entries(messages).map(
                                            ([type, message]) => (
                                              <p key={type}>{message}</p>
                                            )
                                          )
                                        }
                                      </ErrorMessage>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-3 col-md-3 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permzip5
                                  js-form-item-permzip5
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permzip5"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Zip code (xxxxx)
                                  </label>

                                  <input
                                    {...register("permzip5", {
                                      required: "Zip code (xxxxx) is required.",
                                      minLength: {
                                        value: 5,
                                        message:
                                          "Please enter a 5 digit zip code.",
                                      },
                                      maxLength: {
                                        value: 5,
                                        message:
                                          "Zip code (xxxxx) field has a maximum length of 5.",
                                      },
                                      pattern: {
                                        value: /^[-/0-9 -]*$/,
                                        message:
                                          "Only numeric characters are allowed.",
                                      },
                                    })}
                                    value={state.enrollData.permzip5}
                                    onChange={handleChange("permzip5")}
                                    data-drupal-selector="edit-permzip5"
                                    className="form-text required form-control"
                                    data-msg-maxlength="Zip code (xxxxx) field has a maximum length of 5."
                                    data-msg-required="Zip code (xxxxx) is required."
                                    type="text"
                                    id="edit-permzip5"
                                    size="60"
                                    maxLength="5"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permzip5"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>

                                <div
                                  className="
                                  col-sm-3 col-md-3 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permzip4
                                  js-form-item-permzip4
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permzip4"
                                    className="control-label"
                                  >
                                    Zip+4 (xxxx)
                                  </label>

                                  <input
                                    {...register("permzip4", {
                                      minLength: {
                                        value: 4,
                                        message:
                                          "Please enter a 4 digit Zip+4 code.",
                                      },
                                      maxLength: {
                                        value: 5,
                                        message:
                                          "Zip+4 code (xxxx) field has a maximum length of 5.",
                                      },
                                      pattern: {
                                        value: /^[-/0-9 -]*$/,
                                        message:
                                          "Only numeric characters are allowed.",
                                      },
                                    })}
                                    value={state.enrollData.permzip4}
                                    onChange={handleChange("permzip4")}
                                    data-drupal-selector="edit-permzip4"
                                    className="form-text form-control"
                                    data-msg-maxlength="Zip+4 (xxxx) field has a maximum length of 4."
                                    data-msg-required="Zip+4 (xxxx) is required."
                                    type="text"
                                    id="edit-permzip4"
                                    size="60"
                                    maxLength="4"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                              data-drupal-selector="edit-row-4"
                              data-msg-required="This field is required."
                              id="edit-row-4"
                            >
                              <div
                                className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-homephone
                                  js-form-item-homephone
                                  form-group
                                "
                              >
                                <label
                                  htmlFor="edit-homephone"
                                  className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                >
                                  Phone number
                                </label>
                                <br />
                                <CSPhoneInput
                                  name="homephone"
                                  id="edit-homephone"
                                  value={state.enrollData.homephone}
                                  onChange={handleChange("homephone")}
                                  control={control}
                                  rules={{ required: "Home phone number required." }}
                                />
                                {/* <PhoneInput
                                  name="homephone"
                                  value={state.enrollData.homephone}
                                  onChange={handleHomePhone}
                                  country="US"
                                  id="homephone"
                                  control={control}
                                  rules={{
                                    required: "Home phone number required.",
                                    maxLength: {
                                      value: 14,
                                    },
                                  }}
                                  defaultValue={state.enrollData.homephone}
                                  maxLength="14"
                                  className="
                                    col-sm-12 col-md-12
                                    form-item
                                    js-form-item
                                    form-type-textfield
                                    js-form-type-textfield
                                    form-item-homephone
                                    js-form-item-homephone
                                    form-group"
                                  style={{ border: "1px solid #ccc" }}
                                /> */}
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="homephone"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div
                                className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-altphone
                                  js-form-item-altphone
                                  form-group
                                "
                              >
                                <label
                                  htmlFor="edit-altphone"
                                  className="control-label"
                                >
                                  Alternate phone number
                                </label>
                                <br />
                                <CSPhoneInput
                                  name="altphone"
                                  id="edit-altphone"
                                  value={state.enrollData.altphone}
                                  onChange={handleChange("altphone")}
                                  control={control}
                                  //rules = {{required: "Alternate phone number required."}}
                                />
                                {/* <PhoneInput
                                  name="altphone"
                                  value={state.enrollData.altphone}
                                  onChange={handleAltPhone}
                                  country="US"
                                  id="altphone"
                                  control={control}
                                  rules={{
                                    maxLength: {
                                      value: 14,
                                    },
                                  }}
                                  defaultValue={state.enrollData.altphone}
                                  maxLength="14"
                                  className="
                                    col-sm-12 col-md-12
                                    form-item
                                    js-form-item
                                    form-type-textfield
                                    js-form-type-textfield
                                    form-item-altphone
                                    js-form-item-altphone
                                    form-group"
                                  style={{ border: "1px solid #ccc" }}
                                /> */}
                                <div className="error">
                                  <ErrorMessage errors={errors} name="altphone">
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                            <div
                              className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                              data-drupal-selector="edit-row-email-container"
                              data-msg-required="This field is required."
                              id="edit-row-email-container"
                            >
                              <div
                                className="
                                  col-md-6 col-sm-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-email
                                  js-form-item-email
                                  form-group
                                "
                              >
                                <label
                                  htmlFor="edit-email"
                                  className="control-label"
                                >
                                  Email address
                                </label>

                                <input
                                  {...register("email", {
                                    pattern: {
                                      value:
                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message:
                                        "Please enter a valid email address.",
                                    },
                                  })}
                                  value={state.enrollData.email}
                                  onChange={handleChange("email")}
                                  data-drupal-selector="edit-email"
                                  className="form-text form-control"
                                  data-msg-maxlength="Email address field has a maximum length of 255."
                                  data-msg-required="Email address is required."
                                  type="email"
                                  id="edit-email"
                                  size="60"
                                  maxLength="255"
                                />
                                <div className="error">
                                  <ErrorMessage errors={errors} name="email">
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div
                                className="
                                  col-md-6 col-sm-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-confirm-email
                                  js-form-item-confirm-email
                                  form-group
                                "
                              >
                                <label
                                  htmlFor="edit-confirm-email"
                                  className="control-label"
                                >
                                  Confirm email address
                                </label>

                                <input
                                  {...register("confirm_email", {
                                    pattern: {
                                      value:
                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message:
                                        "Please enter a valid email address.",
                                    },
                                    validate: () =>
                                      getValues("confirm_email") ===
                                      getValues("email"),
                                  })}
                                  value={state.enrollData.confirm_email}
                                  onChange={handleChange("confirm_email")}
                                  data-drupal-selector="edit-confirm-email"
                                  className="form-text form-control"
                                  data-msg-maxlength="Confirm email address field has a maximum length of 255."
                                  data-msg-required="Confirm email address is required."
                                  type="text"
                                  id="edit-confirm-email"
                                  size="60"
                                  maxLength="255"
                                />
                                {errors.confirm_email &&
                                  errors.confirm_email.type === "validate" && (
                                    <div className="error">
                                      The email addresses do not match.
                                    </div>
                                  )}
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="confirm_email"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            data-drupal-selector="edit-enroll-in-plan"
                            data-msg-required="Enroll me in the plan below is required."
                            id="edit-enroll-in-plan"
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Medicare information
                              </div>
                            </div>
                            <div className="panel-body">
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-med-row-container"
                                data-msg-required="This field is required."
                                id="edit-med-row-container"
                              >
                                <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-medicareclaimnum js-form-item-medicareclaimnum form-group">
                                  <label
                                    htmlFor="edit-medicareclaimnum"
                                    className="control-label js-form-required form-required"
                                  >
                                    Medicare number (without hyphens)
                                  </label>

                                  <input
                                    {...register("medicareclaimnum", {
                                      required:
                                        "Medicare number (without hyphens) is required.",
                                      maxLength: {
                                        value: 11,
                                        message:
                                          "Medicare number (without hyphens) field has a maximum length of 11.",
                                      },
                                      pattern: {
                                        value:
                                          /^^[1-9]{1}[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz]{1}[0-9]{1}-?[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz]{1}[0-9]{1}-?[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz|^0-9]{1}[0-9]{1}[0-9]{1}/,
                                        message:
                                          "Enter your Medicare number exactly as it appears on your Medicare card without hyphens",
                                      },
                                      // validate: () =>
                                      //  console.log("field validated"),
                                    })}
                                    value={state.enrollData.medicareclaimnum}
                                    onChange={handleChange("medicareclaimnum")}
                                    className="form-text required form-control"
                                    type="text"
                                    id="edit-medicareclaimnum"
                                    size="5"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="medicareclaimnum"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-memberidnumber js-form-item-memberidnumber form-group">
                                  <label
                                    for="edit-memberidnumber"
                                    className="control-label js-form-required form-required"
                                  >
                                    MedicareBlue Rx member number (from your
                                    plan ID card)
                                  </label>

                                  <input
                                    {...register("memberidnumber", {
                                      required:
                                        "MedicareBlue Rx member number (from your plan ID card) is required.",
                                      minLength: {
                                        value: 9,
                                        message:
                                          "Please enter a 9 digit MedicareBlue Rx member number (from your plan ID card).",
                                      },
                                      maxLength: {
                                        value: 9,
                                        message:
                                          "MedicareBlue Rx member number (from your plan ID card) field has a maximum length of 9.",
                                      },
                                      pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Valid Member ID is required",
                                      },
                                      //value: "803",
                                      // validate: () =>
                                      // console.log("field validated"),
                                    })}
                                    value={state.enrollData.memberidnumber}
                                    onChange={handleChange("memberidnumber")}
                                    className="form-text required form-control"
                                    type="text"
                                    id="edit-memberidnumber"
                                    size="60"
                                    maxlength="9"
                                    required="required"
                                    aria-required="true"
                                    defaultValue="803"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="memberidnumber"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          data-drupal-selector="edit-actions"
                          className="
                          form-actions
                          webform-actions
                          form-group
                          js-form-wrapper
                          form-wrapper
                        "
                          data-msg-required="Submit button(s) is required."
                          id="edit-actions"
                        >
                          <Button
                            className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                            onClick={handlePrevious}
                            //disabled={p1}
                            {...(p1 && {
                              style: { display: "none" },
                            })}
                          >
                            Previous
                          </Button>

                          <Button
                            className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                            onClick={handleNextClick}
                          //disabled={!isDirty || !isValid}
                          >
                            {activeStep === steps.length - 1
                              ? "Finish"
                              : activeStep === steps.length - 2
                                ? "Submit"
                                : "Continue to " +
                                steps[activeStep + 1].toString()}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </article>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1_2025;
