import React, { useState } from "react";
import { Link } from "react-router-dom";
import bankInfo from "../../assets/files/inline-images/bank-info.png";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import CSPhoneInput from "../Common/CSPhoneInput";
import CSDateInput from "../Common/CSDateInput";


const ChangeDemographicInfo = ({
  values,
  handleChange,
  setFormValues,
  handleNext,
  activeStep,
  steps,
  phoneOrEmail,
  setPhoneOrEmail,
  permAddress,
  setPermAddress,
  mailAddress,
  setMailAddress,
  billingAddress,
  setBillingAddress,
  birthDate,
  setBirthDate,
  planType,
  setPlanType
}) => {
  const {
    register,
    formState: { errors, isValid },
    getValues,
    control,
    trigger,
    reset
  } = useForm({
    mode: "all",
  });

  const [displayCheckOptionError, setDisplayCheckOptionError] = useState(false);
  const [dobError, setDOBError] = useState(false);

  let bdErr = false;

  const handleNextClick = () => {
    trigger();

    // if (!birthDate) {
    //   bdErr = true;
    //   setDOBError(true);
    // } else {
    //   bdErr = false;
    //   setDOBError(false);
    // }

    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    var checkedOne = Array.prototype.slice
      .call(checkboxes)
      .some((x) => x.checked);

    if (isValid === true && checkedOne === true && bdErr === false) {
      if (planType === "clear") clearPermAddressValues();
      handleNext();
    } else if (!checkedOne) {
      setDisplayCheckOptionError(true);
    }
  };

  const handlePhoneOrEmail = () => {
    let s = !phoneOrEmail;
    setPhoneOrEmail(s);
  };

  // console.log(phoneOrEmail);

  const handlePermAddress = () => {
    let s = !permAddress;
    setPermAddress(s);
    if (permAddress === false) {
      setPlanType("none");
    }
  };

  // console.log(permAddress);

  const handleMailAddress = () => {
    let s = !mailAddress;
    setMailAddress(s);
  };

  const handleBillingAddress = () => {
    let b = !billingAddress;
    setBillingAddress(b);
  };

  // console.log(mailAddress);

  const clearPermAddressValues = () => {
    //if (planType === "none") {
    setFormValues({
      ...values,
      permst1: "",
      permst2: "",
      permcity: "",
      permstate: "",
      permzip5: "",
      permzip4: "",
    });
    //}
  };

  //const [planType, setPlanType] = useState("none");

  const handlePlanType = (e) => {
    let s = e.target.value;
    setFormValues({
      ...values,
      permstate: ""
    });

    reset();

    setPlanType(s);
  };

  // const handleHomePhone = (e) => {
  //   setFormValues({ ...values, homephone: e.replace("+1", "") });
  // };

  // const handleAltPhone = (e) => {
  //   setFormValues({ ...values, altphone: e.replace("+1", "") });
  // };

  const defaultDate = moment().subtract(65, "years").format("YYYY-MM-DD");

  var d = new Date();

  const handleBirthDate = (e) => {
    let date = moment(e).format("YYYY-MM-DD");
    setBirthDate(e);
    setFormValues({ ...values, dob: date });
  };

  // console.log("Birthdate: " + values.dob);

  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy', { minYear :1800, maxYear :2999 });

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <article className="webform full clearfix">
              <div className="content">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  <p>
                    <span className="form-required"> </span> indicates required
                    information
                  </p>
                </div>

                <div className="field field--name-webform field--type-webform field--label-hidden field__item">
                  <form
                    className="webform-submission-form webform-submission-add-form webform-submission-mbrx-update-contact-information-form webform-submission-mbrx-update-contact-information-add-form webform-submission-mbrx-update-contact-information-node-138-form webform-submission-mbrx-update-contact-information-node-138-add-form js-webform-disable-autosubmit js-webform-details-toggle webform-details-toggle"
                    action="mbrx-update-contact-information.html"
                    method="post"
                    id="webform-submission-mbrx-update-contact-information-node-138-add-form"
                    accept-charset="UTF-8"
                  >
                    <div
                      data-webform-key="step_1_page"
                      className="js-form-wrapper form-wrapper form-group"
                      id="edit-step-1-page--2"
                    >
                      <div
                        id="edit-step-1-title"
                        className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                      >
                        <h1>Update your contact information</h1>
                      </div>
                      <div
                        id="edit-form-description"
                        className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                      >
                        Quickly and easily update your contact information
                        online. Please allow up to 7 days for changes to take
                        effect. Contact customer service if you need assistance
                        or have questions.
                      </div>
                      <div
                        className="form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                        id="edit-personal-information"
                      >
                        <div className="panel-heading">
                          <div className="panel-title">
                            Provide your member information
                          </div>
                        </div>
                        <br />
                        <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-1"
                        >
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-firstname js-form-item-firstname form-group">
                            <label
                              for="edit-firstname"
                              className="control-label js-form-required form-required"
                            >
                              Member first name
                            </label>

                            <input
                              {...register("firstname", {
                                required: "First name is required.",
                                maxLength: {
                                  value: 25,
                                  message:
                                    "First name field has a maximum length of 25.",
                                },
                                pattern: {
                                  value: /^[-a-zA-Z0-9 ]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                                validate: (value) => {
                                  return (
                                    !!value.trim() || "First name is required."
                                  );
                                },
                              })}
                              value={values.firstname}
                              onChange={handleChange("firstname")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              className="form-text required form-control"
                              data-msg-maxlength="First name field has a maximum length of 25."
                              data-msg-required="First name is required."
                              data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-firstname"
                              size="60"
                              maxLength="25"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="firstname">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-lastname js-form-item-lastname form-group">
                            <label
                              for="edit-lastname"
                              className="control-label js-form-required form-required"
                            >
                              Member last name
                            </label>

                            <input
                              {...register("lastname", {
                                required: "Last name is required.",
                                maxLength: {
                                  value: 25,
                                  message:
                                    "Last name field has a maximum length of 25.",
                                },
                                pattern: {
                                  value: /^[-a-zA-Z0-9 ]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                                validate: (value) => {
                                  return (
                                    !!value.trim() || "Last name is required."
                                  );
                                },
                              })}
                              value={values.lastname}
                              onChange={handleChange("lastname")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              data-webform-pattern-error="Special characters are not allowed."
                              data-drupal-selector="edit-lastname"
                              className="form-text required form-control"
                              data-msg-maxlength="Last name field has a maximum length of 25."
                              data-msg-required="Last name is required."
                              data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-lastname"
                              size="60"
                              maxLength="25"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="lastname">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-2"
                        >
                          <div className="col-md-6 col-sm-6 webform-has-field-prefix webform-has-field-suffix form-inline form-item js-form-item form-type-date js-form-type-date form-item-dob js-form-item-dob form-group">
                            <div
                              {...{
                                style: { display: "none" },
                              }}
                            >
                              <label for="edit-dob" className="control-label">
                                Member birthdate
                              </label>

                              <div className="input-group">
                                <span className="input-group-addon">
                                  <div className="date_wrap_div">
                                    {/* <input
                                    {...register("dob", {
                                      required: "Birthdate is required.",
                                      min: {
                                        value:
                                          "1911-" + moment().format("MM-DD"),
                                        message:
                                          "The value in Birthdate has to be greater than " +
                                          moment().format("MM/DD") +
                                          "/1911.",
                                      },
                                      max: {
                                        value: moment().format("YYYY-MM-DD"),
                                        message:
                                          "The value in Birthdate has to be less than " +
                                          moment()
                                            .add(1, "days")
                                            .format("MM/DD/YYYY"),
                                      },
                                    })}
                                    onChange={handleChange("dob")}
                                    type="date"
                                    //value={values.dob}
                                    defaultValue={defaultDate}
                                    //min="06/16/1911"
                                    data-min-year="1911"
                                    max="06/16/2021"
                                    data-max-year="2021"
                                    //type="text"
                                    data-drupal-date-format="m/d/Y"
                                    data-drupal-selector="edit-dob"
                                    className="
                                          form-text
                                          required
                                          form-control
                                          hasDatepicker
                                        "
                                    data-msg-max="The value in Birthdate has to be less than 06/16/2021."
                                    data-msg-min="The value in Birthdate has to be greater than 06/16/1911."
                                    data-msg-required="Birthdate is required."
                                    id="edit-dob"
                                    name="dob"
                                    //value=""
                                    required="required"
                                    aria-required="true"
                                    placeholder="MM/DD/YYYY"
                                    autoComplete="chrome-off-48833200"
                                  /> */}

                                    <DatePicker
                                      name="dob"
                                      disabled="true"
                                      onChange={handleBirthDate}
                                      selected={birthDate}
                                      control={control}
                                      // rules={{
                                      //   required: true,
                                      // }}
                                      className="form-text
                                          required
                                          form-control hasDatepicker"
                                      placeholderText="MM/DD/YYYY"
                                      openToDate={d.setYear(
                                        d.getFullYear() - 65
                                      )}
                                      autoComplete="off"

                                      customInput={
                                        <MaskedInput
                                        pipe={autoCorrectedDatePipe}
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        keepCharPositions= {false}
                                        guide = {true}
                                        />   
                                      }
                                    />
                                    <span className="input-group-addon"></span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <br />
                            <div
                              className="error"
                              {...(dobError === false && {
                                style: { display: "none" },
                              })}
                            >
                              Birthdate required.
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-memberidnumber js-form-item-memberidnumber form-group">
                            <label
                              for="edit-memberidnumber"
                              className="control-label js-form-required form-required"
                            >
                              MedicareBlue Rx member number
                            </label>

                            <input
                              {...register("MemberIDNumber", {
                                required:
                                  "MedicareBlue Rx member number (from your plan ID card) is required.",
                                minLength: {
                                  value: 9,
                                  message: "Please enter 9 characters.",
                                },
                                maxLength: {
                                  value: 9,
                                  message:
                                    "MedicareBlue Rx member number (from your plan ID card) field has a maximum length of 9.",
                                },
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Valid Member ID is required",
                                },
                                validate: () => console.log("field validated"),
                              })}
                              value={values.MemberIDNumber}
                              onChange={handleChange("MemberIDNumber")}
                              className="form-text required form-control"
                              type="text"
                              id="edit-memberidnumber"
                              size="60"
                              maxLength="9"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="MemberIDNumber"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          id="edit-request-changes"
                        >
                          <div className="panel-heading">
                            <div className="panel-title">Request changes</div>
                          </div>

                          <div className="panel-body">
                            <div className="form-item js-form-item form-type-checkbox js-form-type-checkbox form-item-select-changes-3 js-form-item-select-changes-3 checkbox">
                              <label
                                for="edit-select-changes-3"
                                className="control-label option"
                              >
                                <input
                                  {...register("phoneOrEmail")}
                                  onChange={handlePhoneOrEmail}
                                  checked={phoneOrEmail}
                                  className="chk-required form-checkbox"
                                  type="checkbox"
                                  id="edit-select-changes-3"
                                  name="select_changes_3"
                                  value="1"
                                />
                                Phone and/or email
                              </label>
                            </div>
                            <div className="form-item js-form-item form-type-checkbox js-form-type-checkbox form-item-select-changes js-form-item-select-changes checkbox">
                              <label
                                for="edit-select-changes"
                                className="control-label option"
                              >
                                <input
                                  {...register("permAddress")}
                                  onChange={handlePermAddress}
                                  checked={permAddress}
                                  className="chk-required form-checkbox"
                                  type="checkbox"
                                  id="edit-select-changes"
                                  name="select_changes"
                                  value="1"
                                />
                                Permanent residence address
                              </label>
                            </div>
                            <div className="form-item js-form-item form-type-checkbox js-form-type-checkbox form-item-select-changes-2 js-form-item-select-changes-2 checkbox">
                              <label
                                for="edit-select-changes-2"
                                className="control-label option"
                              >
                                <input
                                  {...register("mailAddress")}
                                  onChange={handleMailAddress}
                                  checked={mailAddress}
                                  className="chk-required form-checkbox"
                                  type="checkbox"
                                  id="edit-select-changes-2"
                                  name="select_changes_2"
                                  value="1"
                                />
                                Mailing address
                              </label>
                            </div>
                            <div className="form-item js-form-item form-type-checkbox js-form-type-checkbox form-item-select-changes-2 js-form-item-select-changes-2 checkbox">
                              <label
                                for="edit-select-changes-2"
                                className="control-label option"
                              >
                                <input
                                  {...register("billingAddress")}
                                  onChange={handleBillingAddress}
                                  checked={billingAddress}
                                  className="chk-required form-checkbox"
                                  type="checkbox"
                                  id="edit-select-changes-2"
                                  name="select_changes_2"
                                  value="1"
                                />
                                Billing address (<b>ONLY</b> if different from your mailing <b>AND</b> permanent residence address)
                              </label>
                            </div>
                          </div>
                        </div>

                        <div
                          className="js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          id="edit-change-phone-and-or-email"
                          {...(!phoneOrEmail && {
                            style: { display: "none" },
                          })}
                        >
                          <div className="panel-heading">
                            <div className="panel-title">
                              Change phone and/or email
                            </div>
                          </div>

                          <div className="panel-body">
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-row-ph-mail-container"
                            >
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-homephone js-form-item-homephone form-group">
                                <label
                                  for="edit-homephone"
                                  className="control-label"
                                >
                                  Phone number
                                </label>
                                <br />
                                <CSPhoneInput
                                   name="homephone"
                                   id="edit-homephone"
                                   value={values.homephone}                                   
                                   onChange={handleChange("homephone")}
                                   control={control}
                                   //rules = {{required: "Home phone number required."}}
                                />
                                {/* <PhoneInput
                                  name="homephone"
                                  value={values.homephone}
                                  onChange={handleHomePhone}
                                  country="US"
                                  id="homephone"
                                  control={control}
                                  rules={{}}
                                  defaultValue={values.homephone}
                                  className="
                                    col-sm-12 col-md-12
                                    form-item
                                    js-form-item
                                    form-type-textfield
                                    js-form-type-textfield
                                    form-item-homephone
                                    js-form-item-homephone
                                    form-group"
                                  style={{ border: "1px solid #ccc" }}
                                /> */}
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="homephone"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-altphone js-form-item-altphone form-group">
                                <label
                                  for="edit-altphone"
                                  className="control-label"
                                >
                                  Alternate phone number
                                </label>
                                <br />
                                <CSPhoneInput
                                   name="altphone"
                                   id="edit-altphone"
                                   value={values.altphone}                                   
                                   onChange={handleChange("altphone")}
                                   control={control}
                                   //rules = {{required: "Alternate phone number required."}}
                                />
                                {/* <PhoneInput
                                  name="altphone"
                                  value={values.altphone}
                                  onChange={handleAltPhone}
                                  country="US"
                                  id="altphone"
                                  control={control}
                                  defaultValue={values.altphone}
                                  className="
                                    col-sm-12 col-md-12
                                    form-item
                                    js-form-item
                                    form-type-textfield
                                    js-form-type-textfield
                                    form-item-homephone
                                    js-form-item-homephone
                                    form-group"
                                  style={{ border: "1px solid #ccc" }}
                                /> */}
                                <div className="error">
                                  <ErrorMessage errors={errors} name="altphone">
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-row-email-container"
                            >
                              <div className="col-md-6 col-sm-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-email js-form-item-email form-group">
                                <label
                                  for="edit-email"
                                  className="control-label"
                                >
                                  Email address
                                </label>

                                <input
                                  {...register("EmailAddress", {
                                    pattern: {
                                      value:
                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message:
                                        "Please enter a valid email address.",
                                    },
                                    maxLength: {
                                      value: 255,
                                      message:
                                        "Email address field has a maximum length of 255.",
                                    },
                                  })}
                                  value={values.email}
                                  onChange={handleChange("email")}
                                  data-drupal-selector="edit-email"
                                  className="form-text form-control"
                                  data-msg-maxlength="Email address field has a maximum length of 255."
                                  data-msg-required="Email address is required."
                                  type="text"
                                  id="edit-email"
                                  size="60"
                                  maxLength="255"
                                />
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="EmailAddress"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-confirm-email js-form-item-confirm-email form-group">
                                <label
                                  for="edit-confirm-email"
                                  className="control-label"
                                >
                                  Confirm email address
                                </label>

                                <input
                                  {...register("confirm_email", {
                                    pattern: {
                                      value:
                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message:
                                        "Please enter a valid email address.",
                                    },
                                    maxLength: {
                                      value: 255,
                                      message:
                                        "Email address field has a maximum length of 255.",
                                    },

                                    validate: () =>
                                      getValues("confirm_email") ===
                                      getValues("EmailAddress"),
                                  })}
                                  value={values.confirm_email}
                                  onChange={handleChange("confirm_email")}
                                  data-drupal-selector="edit-confirm-email"
                                  className="form-text form-control"
                                  data-msg-maxlength="Confirm email address field has a maximum length of 255."
                                  data-msg-required="Confirm email address is required."
                                  type="text"
                                  id="edit-confirm-email"
                                  size="60"
                                  maxLength="255"
                                />
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="confirm_email"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                                {errors.confirm_email &&
                                  errors.confirm_email.type === "validate" && (
                                    <div className="error">
                                      The email addresses do not match.
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          {...(!permAddress && {
                            style: { display: "none" },
                          })}
                        >
                          <div
                            className="js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                            id="edit-individual-group-member"
                          >
                            <div className="panel-heading">
                              <div className="panel-title form-required">
                                Choose your plan type
                              </div>
                            </div>

                            <div className="panel-body">
                              <fieldset
                                className="radios--wrapper fieldgroup form-composite webform-composite-visible-title js-webform-type-radios webform-type-radios js-form-item form-item js-form-wrapper form-wrapper"
                                id="edit-individual-or-group--wrapper"
                              >
                                <legend>
                                  <span className="fieldset-legend">
                                    Individual or group
                                  </span>
                                </legend>
                                <div className="fieldset-wrapper">
                                  <div
                                    id="edit-individual-or-group"
                                    className="js-webform-radios webform-options-display-one-column"
                                  >
                                    <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-individual-or-group js-form-item-individual-or-group radio">
                                      <label
                                        for="edit-individual-or-group-individual"
                                        className="control-label option"
                                      >
                                        <input
                                          onChange={handlePlanType}
                                          checked={planType === "individual"}
                                          className="form-radio"
                                          type="radio"
                                          id="edit-individual-or-group-individual"
                                          name="individual_or_group"
                                          value="individual"
                                        />
                                        Individual plan member
                                      </label>
                                    </div>
                                    <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-individual-or-group js-form-item-individual-or-group radio">
                                      <label
                                        for="edit-individual-or-group-group"
                                        className="control-label option"
                                      >
                                        <input
                                          onChange={handlePlanType}
                                          checked={planType === "group"}
                                          className="form-radio"
                                          type="radio"
                                          id="edit-individual-or-group-group"
                                          name="individual_or_group"
                                          value="group"
                                        />
                                        Employer group plan member
                                      </label>
                                    </div>
                                    <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-individual-or-group js-form-item-individual-or-group radio">
                                      <label
                                        for="edit-individual-or-group-none"
                                        className="control-label option"
                                      >
                                        <input
                                          onChange={handlePlanType}
                                          checked={planType === "clear"}
                                          className="form-radio"
                                          type="radio"
                                          id="edit-individual-or-group-none"
                                          name="individual_or_group"
                                          value="clear"
                                        />
                                        Clear selection if you decide not to
                                        change your permanent address
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>

                          <div
                            className="js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                            id="edit-change-permanent-resident-address"
                            {...((planType === "none" ||
                              planType === "clear") && {
                              style: { display: "none" },
                            })}
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                Change permanent resident address
                              </div>
                            </div>

                            <div className="panel-body">
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                id="edit-row-address1-container"
                              >
                                <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-permst1 js-form-item-permst1 form-group">
                                  <label
                                    for="edit-permst1"
                                    className="control-label form-required"
                                  >
                                    Street address
                                  </label>

                                  <input
                                    {...register("permst1", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            (planType === "individual" ||
                                              planType === "group")
                                          )
                                            return "Street address is required.";
                                          return true;
                                        },
                                      },
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Street address field has a maximum length of 30.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={values.permst1}
                                    onChange={handleChange("permst1")}
                                    pattern="^[-/a-zA-Z0-9 -]*$"
                                    className="form-text required form-control"
                                    data-msg-maxlength="Street address field has a maximum length of 30."
                                    data-msg-required="Street address is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-permst1"
                                    size="60"
                                    maxLength="30"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permst1"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>

                                  <div className="description help-block">
                                    <div
                                      id="edit-permst1--description"
                                      className="webform-element-description form-group"
                                    >
                                      (P.O. Box not allowed)
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-permst2 js-form-item-permst2 form-group">
                                  <label
                                    for="edit-permst2"
                                    className="control-label"
                                  >
                                    Address 2
                                  </label>

                                  <input
                                    {...register("permst2", {
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Street address field has a maximum length of 30.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={values.permst2}
                                    onChange={handleChange("permst2")}
                                    pattern="^[-/a-zA-Z0-9 ]*$"
                                    className="form-text form-control"
                                    data-msg-maxlength="Address 2 field has a maximum length of 30."
                                    data-msg-required="Address 2 is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-permst2"
                                    size="60"
                                    maxLength="30"
                                  />
                                </div>
                              </div>
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                id="edit-row-addr2-container"
                              >
                                <div className="col-sm-3 col-md-3 form-item js-form-item form-type-textfield js-form-type-textfield form-item-permcity js-form-item-permcity form-group">
                                  <label
                                    for="edit-permcity"
                                    className="control-label form-required"
                                  >
                                    City
                                  </label>

                                  <input
                                    {...register("permcity", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            (planType === "individual" ||
                                              planType === "group")
                                          )
                                            return "Street address is required.";
                                          return true;
                                        },
                                      },
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "City field has a maximum length of 25.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={values.permcity}
                                    onChange={handleChange("permcity")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    className="form-text required form-control"
                                    data-msg-maxlength="City field has a maximum length of 25."
                                    data-msg-required="City is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-permcity"
                                    size="60"
                                    maxLength="25"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permcity"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div id="new-state">
                                  <div className="col-sm-3 col-md-3 form-item js-form-item form-type-select js-form-type-select form-item-permstate js-form-item-permstate form-group">
                                    <label
                                      for="edit-permstate"
                                      className="control-label form-required"
                                    >
                                      State
                                    </label>

                                    <div className="select-wrapper">
                                      {planType === "individual" ?
                                        <select
                                          {...register("permstate", {
                                            validate: {
                                              required: (value) => {
                                                if (
                                                  !value &&
                                                  (planType === "individual" ||
                                                    planType === "group")
                                                )
                                                  return "State is required.";
                                                return true;
                                              },
                                            },
                                          })}
                                          value={values.permstate}
                                          onChange={handleChange("permstate")}
                                          data-drupal-selector="edit-permstate"
                                          className="form-select required form-control"
                                          data-msg-required="State is required."
                                          id="edit-permstate"
                                          required="required"
                                          aria-required="true"
                                        >
                                          <option value="">- Select -</option>
                                          <option value="IA">Iowa</option>
                                          <option value="MN">Minnesota</option>
                                          <option value="MT">Montana</option>
                                          <option value="NE">Nebraska</option>
                                          <option value="ND">North Dakota</option>
                                          <option value="SD">South Dakota</option>
                                          <option value="WY">Wyoming</option>
                                        </select>
                                        :
                                        <select
                                          {...register("permstate", {
                                            validate: {
                                              required: (value) => {
                                                if (
                                                  !value &&
                                                  (planType === "individual" ||
                                                    planType === "group")
                                                )
                                                  return "State is required.";
                                                return true;
                                              },
                                            },
                                          })}
                                          value={values.permstate}
                                          onChange={handleChange("permstate")}
                                          data-drupal-selector="edit-permstate"
                                          className="form-select required form-control"
                                          data-msg-required="State is required."
                                          id="edit-permstate"
                                          required="required"
                                          aria-required="true"
                                        >
                                          <option value="">- Select -</option>
                                          <option value="AL">Alabama</option>
                                          <option value="AK">Alaska</option>
                                          <option value="AZ">Arizona</option>
                                          <option value="AR">Arkansas</option>
                                          <option value="CA">California</option>
                                          <option value="CO">Colorado</option>
                                          <option value="CT">Connecticut</option>
                                          <option value="DE">Delaware</option>
                                          <option value="DC">
                                            District of Columbia
                                          </option>
                                          <option value="FL">Florida</option>
                                          <option value="GA">Georgia</option>
                                          <option value="HI">Hawaii</option>
                                          <option value="ID">Idaho</option>
                                          <option value="IL">Illinois</option>
                                          <option value="IN">Indiana</option>
                                          <option value="IA">Iowa</option>
                                          <option value="KS">Kansas</option>
                                          <option value="KY">Kentucky</option>
                                          <option value="LA">Louisiana</option>
                                          <option value="ME">Maine</option>
                                          <option value="MD">Maryland</option>
                                          <option value="MA">Massachusetts</option>
                                          <option value="MI">Michigan</option>
                                          <option value="MN">Minnesota</option>
                                          <option value="MS">Mississippi</option>
                                          <option value="MO">Missouri</option>
                                          <option value="MT">Montana</option>
                                          <option value="NE">Nebraska</option>
                                          <option value="NV">Nevada</option>
                                          <option value="NH">New Hampshire</option>
                                          <option value="NJ">New Jersey</option>
                                          <option value="NM">New Mexico</option>
                                          <option value="NY">New York</option>
                                          <option value="NC">North Carolina</option>
                                          <option value="ND">North Dakota</option>
                                          <option value="OH">Ohio</option>
                                          <option value="OK">Oklahoma</option>
                                          <option value="OR">Oregon</option>
                                          <option value="PA">Pennsylvania</option>
                                          <option value="RI">Rhode Island</option>
                                          <option value="SC">South Carolina</option>
                                          <option value="SD">South Dakota</option>
                                          <option value="TN">Tennessee</option>
                                          <option value="TX">Texas</option>
                                          <option value="UT">Utah</option>
                                          <option value="VT">Vermont</option>
                                          <option value="VA">Virginia</option>
                                          <option value="WA">Washington</option>
                                          <option value="WV">West Virginia</option>
                                          <option value="WI">Wisconsin</option>
                                          <option value="WY">Wyoming</option>
                                        </select>
                                      }
                                      <div className="error">
                                        <ErrorMessage
                                          errors={errors}
                                          name="permstate"
                                        >
                                          {({ messages }) =>
                                            messages &&
                                            Object.entries(messages).map(
                                              ([type, message]) => (
                                                <p key={type}>{message}</p>
                                              )
                                            )
                                          }
                                        </ErrorMessage>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-3 col-md-3 form-item js-form-item form-type-textfield js-form-type-textfield form-item-permzip5 js-form-item-permzip5 form-group">
                                  <label
                                    for="edit-permzip5"
                                    className="control-label form-required"
                                  >
                                    Zip code (xxxxx)
                                  </label>

                                  <input
                                    {...register("permzip5", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            (planType === "individual" ||
                                              planType === "group")
                                          )
                                            return "Zip code is required.";
                                          return true;
                                        },
                                      },
                                      minLength: {
                                        value: 5,
                                        message:
                                          "Please enter a 5 digit Zip code.",
                                      },
                                      maxLength: {
                                        value: 5,
                                        message:
                                          "Zip code (xxxxx) field has a maximum length of 5.",
                                      },
                                      pattern: {
                                        value: /^[-/0-9 -]*$/,
                                        message:
                                          "Only numeric characters are allowed.",
                                      },
                                    })}
                                    value={values.permzip5}
                                    onChange={handleChange("permzip5")}
                                    data-drupal-selector="edit-permzip5"
                                    className="form-text required form-control"
                                    data-msg-maxlength="Zip code (xxxxx) field has a maximum length of 5."
                                    data-msg-required="Zip code (xxxxx) is required."
                                    type="text"
                                    id="edit-permzip5"
                                    size="60"
                                    maxLength="5"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permzip5"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div className="col-sm-3 col-md-3 form-item js-form-item form-type-textfield js-form-type-textfield form-item-permzip4 js-form-item-permzip4 form-group">
                                  <label
                                    for="edit-permzip4"
                                    className="control-label"
                                  >
                                    Zip+4 (xxxx)
                                  </label>

                                  <input
                                    {...register("permzip4", {
                                      minLength: {
                                        value: 5,
                                        message:
                                          "Please enter a 4 digit Zip+4 code.",
                                      },
                                      maxLength: {
                                        value: 5,
                                        message:
                                          "Zip+4 code (xxxx) field has a maximum length of 5.",
                                      },
                                    })}
                                    value={values.permzip4}
                                    onChange={handleChange("permzip4")}
                                    className="form-text form-control"
                                    type="text"
                                    id="edit-permzip4"
                                    size="60"
                                    maxLength="4"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permzip4"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="js-form-wrapper js-webform-states-hidden"
                            {...(planType !== "individual" && {
                              style: { display: "none" },
                            })}
                          >
                            <div
                              className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                              id="edit-do-not-proceed"
                            >
                              <p>
                                <strong>
                                  If your state is not listed or if you are
                                  moving out of the country, do not proceed.
                                </strong>{" "}
                                Please contact customer service at{" "}
                                <Link to="tel:1-888-832-0075">
                                  <strong>1-888-832-0075</strong>
                                </Link>
                                , 8 a.m. to 8 p.m., daily, Central and Mountain
                                times. TTY hearing impaired users call{" "}
                                <Link to="tel:711">
                                  <strong>711</strong>
                                </Link>
                              </p>
                            </div>
                          </div>
                          <div
                            className="js-form-wrapper js-webform-states-hidden"
                            {...(planType !== "group" && {
                              style: { display: "none" },
                            })}
                          >
                            <div
                              className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                              id="edit-do-not-proceed-1"
                            >
                              <p>
                                <strong>
                                  If you are moving out of the country, do not
                                  proceed.
                                </strong>{" "}
                                Please contact customer service at{" "}
                                <Link to="tel:1-877-838-3827">
                                  <strong>1-877-838-3827</strong>
                                </Link>
                                , 8 a.m. to 8 p.m., daily, Central and Mountain
                                times. TTY hearing impaired users call{" "}
                                <Link to="tel:711">
                                  <strong>711</strong>
                                </Link>
                              </p>

                              <p> </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          id="edit-change-mailing-address-only-if-different-from-your-permanent-res"
                          {...(!mailAddress && {
                            style: { display: "none" },
                          })}
                        >
                          <div className="panel-heading">
                            <div className="panel-title">
                              Change mailing address (only if different from
                              your permanent residence address)
                            </div>
                          </div>

                          <div className="panel-body">
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-mailing-row1-container"
                            >
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-mailst1 js-form-item-mailst1 form-group">
                                <label
                                  for="edit-mailst1"
                                  className="control-label"
                                >
                                  Mailing address
                                </label>

                                <input
                                  {...register("mailst1")}
                                  value={values.mailst1}
                                  onChange={handleChange("mailst1")}
                                  pattern="^[-/a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-dst1"
                                  className="form-text form-control"
                                  data-msg-maxlength="Mailing address field has a maximum length of 30."
                                  data-msg-required="Mailing address is required."
                                  data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-dst1"
                                  size="60"
                                  maxLength="30"
                                  data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                />
                              </div>
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-mailst2 js-form-item-mailst2 form-group">
                                <label
                                  for="edit-mailst2"
                                  className="control-label"
                                >
                                  Mailing address 2
                                </label>

                                <input
                                  {...register("mailst2")}
                                  value={values.mailst2}
                                  onChange={handleChange("mailst2")}
                                  pattern="^[-/a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-dst2"
                                  className="form-text form-control"
                                  data-msg-maxlength="Mailing address 2 field has a maximum length of 30."
                                  data-msg-required="Mailing address 2 is required."
                                  data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-dst2"
                                  size="60"
                                  maxLength="30"
                                />
                              </div>
                            </div>
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-mailing-row2-container"
                            >
                              <div className="col-sm-3 col-md-3 form-item js-form-item form-type-textfield js-form-type-textfield form-item-mailcity js-form-item-mailcity form-group">
                                <label
                                  for="edit-mailcity"
                                  className="control-label"
                                >
                                  Mailing city
                                </label>

                                <input
                                  {...register("mailcity")}
                                  value={values.mailcity}
                                  onChange={handleChange("mailcity")}
                                  pattern="^[a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-dcity"
                                  className="form-text form-control"
                                  data-msg-maxlength="Mailing city field has a maximum length of 30."
                                  data-msg-required="Mailing city is required."
                                  data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-dcity"
                                  size="60"
                                  maxLength="30"
                                  data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                />
                              </div>
                              <div className="col-sm-3 col-md-3 form-item js-form-item form-type-select js-form-type-select form-item-mailst js-form-item-mailst form-group">
                                <label
                                  for="edit-mailst"
                                  className="control-label"
                                >
                                  Mailing state
                                </label>

                                <div className="select-wrapper">
                                  <select
                                    {...register("mailst")}
                                    value={values.mailst}
                                    onChange={handleChange("mailst")}
                                    data-drupal-selector="edit-dst"
                                    className="form-select form-control"
                                    data-msg-required="Mailing state is required."
                                    id="edit-dst"
                                    data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                  >
                                    <option value="">- Select -</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">
                                      District of Columbia
                                    </option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-3 col-md-3 form-item js-form-item form-type-textfield js-form-type-textfield form-item-mailzip5 js-form-item-mailzip5 form-group">
                                <label
                                  for="edit-mailzip5"
                                  className="control-label"
                                >
                                  Mailing zip code (xxxxx)
                                </label>

                                <input
                                  {...register("mailzip5")}
                                  value={values.mailzip5}
                                  onChange={handleChange("mailzip5")}
                                  data-drupal-selector="edit-dzip5"
                                  className="form-text form-control"
                                  data-msg-maxlength="Mailing zip code (xxxxx) field has a maximum length of 5."
                                  data-msg-required="Mailing zip code (xxxxx) is required."
                                  type="text"
                                  id="edit-dzip5"
                                  size="60"
                                  maxLength="5"
                                  data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                />
                              </div>
                              <div className="col-sm-3 col-md-3 form-item js-form-item form-type-textfield js-form-type-textfield form-item-mailzip4 js-form-item-mailzip4 form-group">
                                <label
                                  for="edit-mailzip4"
                                  className="control-label"
                                >
                                  Mailing zip+4 (xxxx)
                                </label>

                                <input
                                  {...register("mailzip4")}
                                  value={values.mailzip4}
                                  onChange={handleChange("mailzip4")}
                                  data-drupal-selector="edit-dzip4"
                                  className="form-text form-control"
                                  data-msg-maxlength="Mailing zip+4 field has a maximum length of 4."
                                  data-msg-required="Mailing zip+4 is required."
                                  type="text"
                                  id="edit-dzip4"
                                  size="60"
                                  maxLength="4"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          id="edit-change-billing-address-only-if-different-from-your-permanent-res"
                          {...(!billingAddress && {
                            style: { display: "none" },
                          })}
                        >
                          <div className="panel-heading">
                            <div className="panel-title">
                              Change billing address (<b>ONLY</b> if different from your mailing <b>AND</b> permanent residence address)
                            </div>
                          </div>

                          <div className="panel-body">
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-mailing-row1-container"
                            >
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-billingaddress1 js-form-item-billingaddress1 form-group">
                                <label
                                  for="edit-billingaddress1"
                                  className="control-label"
                                >
                                  Billing address
                                </label>

                                <input
                                  {...register("billingaddress1", {
                                  validate: {
                                    required: (value) => {
                                      if (
                                        !value &&
                                        billingAddress
                                      )
                                        return "Street address is required.";
                                      return true;
                                    },
                                  },
                                  maxLength: {
                                    value: 30,
                                    message:
                                      "Street address field has a maximum length of 30.",
                                  },
                                  pattern: {
                                    value: /^[-/a-zA-Z0-9 -]*$/,
                                    message:
                                      "Special characters are not allowed.",
                                  },
                                  })}
                                  value={values.billingaddress1}
                                  onChange={handleChange("billingaddress1")}
                                  pattern="^[-/a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-billingaddress1"
                                  className="form-text form-control"
                                  data-msg-maxlength="Billing address field has a maximum length of 30."
                                  data-msg-required="Billing address is required."
                                  data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-billingaddress1"
                                  size="60"
                                  maxLength="30"
                                  data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_billing_address\u0022]":{"checked":true}}}'
                                />
                                <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="billingaddress1"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                              </div>
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-billingaddress2 js-form-item-billingaddress2 form-group">
                                <label
                                  for="edit-billingaddress2"
                                  className="control-label"
                                >
                                  Billing address 2
                                </label>

                                <input
                                  {...register("billingaddress2")}
                                  value={values.billingaddress2}
                                  onChange={handleChange("billingaddress2")}
                                  pattern="^[-/a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-billingaddress2"
                                  className="form-text form-control"
                                  data-msg-maxlength="Billing address 2 field has a maximum length of 30."
                                  data-msg-required="Billing address 2 is required."
                                  data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-billingaddress2"
                                  size="60"
                                  maxLength="30"
                                />
                              </div>
                            </div>
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-billing-row2-container"
                            >
                              <div className="col-sm-3 col-md-3 form-item js-form-item form-type-textfield js-form-type-textfield form-item-billingcity js-form-item-billingcity form-group">
                                <label
                                  for="edit-billingcity"
                                  className="control-label"
                                >
                                  Billing city
                                </label>

                                <input
                                  {...register("billingcity", {
                                    validate: {
                                      required: (value) => {
                                        if (
                                          !value &&
                                          billingAddress
                                        )
                                          return "City is required..";
                                        return true;
                                      },
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "City field has a maximum length of 25.",
                                    },
                                    pattern: {
                                      value: /^[-/a-zA-Z0-9 -]*$/,
                                      message:
                                        "Special characters are not allowed.",
                                    },
                                  })}
                                  value={values.billingcity}
                                  onChange={handleChange("billingcity")}
                                  pattern="^[a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-billingcity"
                                  className="form-text form-control"
                                  data-msg-maxlength="Billing city field has a maximum length of 30."
                                  data-msg-required="Billing city is required."
                                  data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-billingcity"
                                  size="60"
                                  maxLength="30"
                                  data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_billing_address\u0022]":{"checked":true}}}'
                                />
                                <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="billingcity"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                              </div>

                              <div className="col-sm-3 col-md-3 form-item js-form-item form-type-select js-form-type-select form-item-billingst js-form-item-billingst form-group">
                                <label
                                  for="edit-billingst"
                                  className="control-label"
                                >
                                  Billing state
                                </label>

                                <div className="select-wrapper">
                                  <select
                                    {...register("billingst", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            billingAddress
                                          )
                                            return "State is required.";
                                          return true;
                                        },
                                      },
                                    })}
                                    value={values.billingst}
                                    onChange={handleChange("billingst")}
                                    data-drupal-selector="edit-billingst"
                                    className="form-select form-control"
                                    data-msg-required="Billing state is required."
                                    id="edit-billingst"
                                    data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_billing_address\u0022]":{"checked":true}}}'
                                  >
                                    <option value="">- Select -</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">
                                      District of Columbia
                                    </option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                  </select>
                                  <div className="error">
                                      <ErrorMessage
                                        errors={errors}
                                        name="billingst"
                                      >
                                        {({ messages }) =>
                                          messages &&
                                          Object.entries(messages).map(
                                            ([type, message]) => (
                                              <p key={type}>{message}</p>
                                            )
                                          )
                                        }
                                      </ErrorMessage>
                                    </div>
                                </div>
                              </div>

                              <div className="col-sm-3 col-md-3 form-item js-form-item form-type-textfield js-form-type-textfield form-item-billingzip5 js-form-item-billingzip5 form-group">
                                <label
                                  for="edit-mailbillingzip5ip5"
                                  className="control-label"
                                >
                                  Billing zip code (xxxxx)
                                </label>

                                <input
                                  {...register("billingzip5", {
                                    validate: {
                                      required: (value) => {
                                        if (
                                          !value &&
                                          billingAddress
                                        )
                                          return "Zip code (xxxxx) is required.";
                                        return true;
                                      },
                                    },
                                    maxLength: {
                                      value: 5,
                                      message:
                                        "Zip code (xxxxx) field has a maximum length of 5.",
                                    },
                                    pattern: {
                                      value: /^[-/0-9 -]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  value={values.billingzip5}
                                  onChange={handleChange("billingzip5")}
                                  data-drupal-selector="edit-billingzip5"
                                  className="form-text form-control"
                                  data-msg-maxlength="Billing zip code (xxxxx) field has a maximum length of 5."
                                  data-msg-required="Billing zip code (xxxxx) is required."
                                  type="text"
                                  id="edit-billingzip5"
                                  size="60"
                                  maxLength="5"
                                  data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_billing_address\u0022]":{"checked":true}}}'
                                />
                                <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="billingzip5"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                              </div>

                              <div className="col-sm-3 col-md-3 form-item js-form-item form-type-textfield js-form-type-textfield form-item-billingzip4 js-form-item-billingzip4 form-group">
                                <label
                                  for="edit-billingzip4"
                                  className="control-label"
                                >
                                  Billing zip+4 (xxxx)
                                </label>

                                <input
                                  {...register("billingzip4")}
                                  value={values.billingzip4}
                                  onChange={handleChange("billingzip4")}
                                  data-drupal-selector="edit-billingzip4"
                                  className="form-text form-control"
                                  data-msg-maxlength="Billing zip+4 field has a maximum length of 4."
                                  data-msg-required="Billing zip+4 is required."
                                  type="text"
                                  id="edit-billingzip4"
                                  size="60"
                                  maxLength="4"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          id="edit-applicant-or-authorized-representative-signature"
                        >
                          <div className="panel-heading">
                            <div className="panel-title">
                              Applicant or authorized representative signature
                            </div>
                          </div>

                          <div className="panel-body">
                            <div
                              id="edit-applicant-authorized-rep-sig-description"
                              className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                            >
                              <p>
                                <strong>I understand</strong> that typing my
                                name below means I have read and understand the
                                contents of this form.
                              </p>

                              <p>
                                If this is submitted by an individual who is the
                                authorized representative of the member and
                                authorized to act on his/her behalf under the
                                laws of the state where the enrollee lives, then
                                this signature certifies that this person is
                                authorized under state law to complete this
                                form.
                              </p>
                            </div>
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-applicant-container"
                            >
                              <div className="col-md-6 col-sm-6 form-inline form-item js-form-item form-type-date js-form-type-date form-item-datesigned js-form-item-datesigned form-disabled form-group">
                                <label
                                  for="edit-datesigned"
                                  className="control-label"
                                >
                                  Today's date
                                </label>

                                <input
                                  type="text"
                                  disabled="disabled"
                                  className="form-text form-control"
                                  id="edit-datesigned"
                                  name="datesigned"
                                  value={moment().format("MM/DD/YYYY")}
                                  placeholder="MM/DD/YYYY"
                                />
                              </div>
                              <div className="col-sm-6 col-md-6 webform-has-field-prefix webform-has-field-suffix form-inline form-item js-form-item form-type-date js-form-type-date form-item-demographicseffectivedate js-form-item-demographicseffectivedate form-group">
                                <label
                                  for="edit-demographicseffectivedate"
                                  className="control-label form-required"
                                >
                                  Request effective date for changes to take
                                  effect
                                </label>

                                <div className="input-group">
                                  <span className="input-group-addon">
                                    <div className="date_wrap_div">
                                      {/* <input
                                        {...register(
                                          "demographicseffectivedate",
                                          {
                                            required:
                                              "Requested effective date is required.",
                                            // min: {
                                            //   value:
                                            //     "1911-" +
                                            //     moment().format("MM-DD"),
                                            //   message:
                                            //     "The value in Requested Effective Date has to be greater than " +
                                            //     moment().format("MM/DD") +
                                            //     "/1911.",
                                            // },
                                            // max: {
                                            //   value:
                                            //     moment().format("YYYY-MM-DD"),
                                            //   message:
                                            //     "The value in Birthdate has to be less than " +
                                            //     moment()
                                            //       .add(1, "days")
                                            //       .format("MM/DD/YYYY"),
                                            // },
                                          }
                                        )}
                                        onChange={handleChange(
                                          "demographicseffectivedate"
                                        )}
                                        type="date"
                                        value={values.demographicseffectivedate}
                                        //min="06/16/1911"
                                        data-min-year="1911"
                                        max="06/16/2021"
                                        data-max-year="2021"
                                        //type="text"
                                        data-drupal-date-format="m/d/Y"
                                        data-drupal-selector="edit-demographicseffectivedate"
                                        className="
                                          form-text
                                          required
                                          form-control
                                          hasDatepicker
                                        "
                                        data-msg-max="The value in Birthdate has to be less than 06/16/2021."
                                        data-msg-min="The value in Birthdate has to be greater than 06/16/1911."
                                        data-msg-required="Birthdate is required."
                                        id="edit-dob"
                                        //value=""
                                        aria-required="true"
                                        placeholder="MM/DD/YYYY"
                                        autoComplete="chrome-off-48833200"
                                        defaultValue={moment().format(
                                          "YYYY-MM-DD"
                                        )}
                                      /> */}
                                      <CSDateInput
                                          name="demographicseffectivedate"
                                          rules={{ 
                                              validate: {
                                                requiredValidation:  value => { if (!value) return "Requested effective date is required."; return true }
                                              }
                                            }}  
                                          control={control}
                                          defaultValue={values.demographicseffectivedate}  // needed for initial form validation 
                                          value={values.demographicseffectivedate}
                                          onChange={handleChange("demographicseffectivedate")}
                                          className="form-text
                                          required
                                          form-control"                                             
                                      />    
                                      {/* <span className="input-group-addon"></span> */}
                                    </div>
                                  </span>
                                </div>
                                <br />
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="demographicseffectivedate"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-row-applicant-2"
                            >
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-signaturefirst js-form-item-signaturefirst form-group">
                                <label
                                  for="edit-signaturefirst"
                                  className="control-label js-form-required form-required"
                                >
                                  First name
                                </label>

                                <input
                                  {...register("signaturefirst", {
                                    required:
                                      "Signature first name is required.",
                                    pattern: {
                                      value: /^[-a-zA-Z0-9 ]*$/,
                                      message:
                                        "Special characters are not allowed.",
                                    },
                                  })}
                                  value={values.signaturefirst}
                                  onChange={handleChange("signaturefirst")}
                                  pattern="^[-a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-signaturefirst"
                                  className="form-text required form-control"
                                  // data-msg-maxLength="Enrollee first name field has a maximum length of 25."
                                  // data-msg-required="Enrollee first name is required."
                                  // data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-signaturefirst"
                                  //name="signaturefirst"
                                  //value=""
                                  size="60"
                                  maxLength="25"
                                  required="required"
                                  aria-required="true"
                                />
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="signaturefirst"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-signaturelast js-form-item-signaturelast form-group">
                                <label
                                  for="edit-signaturelast"
                                  className="control-label js-form-required form-required"
                                >
                                  Last name
                                </label>

                                <input
                                  {...register("signaturelast", {
                                    required:
                                      "Signature last name is required.",
                                    pattern: {
                                      value: /^[-a-zA-Z0-9 ]*$/,
                                      message:
                                        "Special characters are not allowed.",
                                    },
                                  })}
                                  value={values.signaturelast}
                                  onChange={handleChange("signaturelast")}
                                  pattern="^[-a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-signaturelast"
                                  className="form-text required form-control"
                                  // data-msg-maxLength="Enrollee last name field has a maximum length of 25."
                                  // data-msg-required="Enrollee last name is required."
                                  // data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-signaturelast"
                                  // name="signaturelast"
                                  // value=""
                                  size="60"
                                  maxLength="25"
                                  required="required"
                                  aria-required="true"
                                />
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="signaturelast"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                            <input type="hidden" name="signature" value="Y" />
                          </div>
                        </div>
                      </div>
                      <input
                        type="hidden"
                        name="transactiontype"
                        value="DEMOGR"
                      />
                      <input type="hidden" name="recversion" value="2020-01" />
                      <input type="hidden" name="ipaddress" value="UPDATING" />
                      <input type="hidden" name="dor" value="10/07/2020" />
                      <input type="hidden" name="timestamp" value="14:26:09" />
                      <input
                        type="hidden"
                        name="conf_number"
                        value="no_confirmation"
                      />
                      <input
                        autocomplete="off"
                        type="hidden"
                        name="form_build_id"
                        value="form-Fce02cnPsciHQpMKlhG2hslAoC4l4Wrh_JzAuI82-0s"
                      />
                      <input
                        type="hidden"
                        name="form_id"
                        value="webform_submission_mbrx_update_contact_information_node_138_add_form"
                      />
                      <div
                        className="form-actions form-group js-form-wrapper form-wrapper"
                        id="edit-actions"
                      >
                        <Button
                          className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                          onClick={handleNextClick}
                        >
                          {activeStep === steps.length - 1
                            ? "Finish"
                            : activeStep === steps.length - 2
                            ? "Submit"
                            : "Continue to " + steps[activeStep + 1].toString()}
                        </Button>
                        <div
                          className="error"
                          {...(displayCheckOptionError !== true && {
                            style: { display: "none" },
                          })}
                        >
                          At least one item must be selected.
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ChangeDemographicInfo;
